import React, { useContext } from "react";
import {
  faBriefcase,
  faCashRegister,
  faHandHoldingDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
import { NavLink } from "react-router-dom";
import { getRuta, isAccess } from "../../helpers/Helpers";
import { DataContext } from "../../context/DataContext";

const Administracion = ({ drawerIsOpen }) => {
  const [open, setOpen] = React.useState(false);
  let rout = getRuta();
  const { access } = useContext(DataContext);
  return (
    <div>
      <ListItemButton onClick={() => setOpen(!open)} style={{ padding: 0 }}>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={faBriefcase}
            style={{
              fontSize: 22,
            }}
          />
        </ListItemIcon>
        <ListItemText primary="Administracion" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <Stack marginTop={1} paddingLeft={drawerIsOpen ? 4 : 0.5} spacing={1}>
          {isAccess(access, "CAJA VER") ? (
            <NavLink
              to={`${rout}/caja`}
              className={(navData) =>
                navData.isActive ? "active" : "inactive"
              }
            >
              <Stack spacing={3} direction="row" alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faCashRegister}
                  style={{
                    fontSize: 22,
                  }}
                />
                <Typography>Caja</Typography>
              </Stack>
            </NavLink>
          ) : (
            <></>
          )}

          {isAccess(access, "ADMON VER") ? (
            <>
              <NavLink
                to={`${rout}/gastos`}
                className={(navData) =>
                  navData.isActive ? "active" : "inactive"
                }
              >
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <FontAwesomeIcon
                    icon={faHandHoldingDollar}
                    style={{
                      fontSize: 22,
                    }}
                  />
                  <Typography>Gastos</Typography>
                </Stack>
              </NavLink>

              {/* <NavLink
                to={`${rout}/contabilidad`}
                className={(navData) =>
                  navData.isActive ? "active" : "inactive"
                }
              >
                <Stack spacing={3} direction="row" alignItems={"center"}>
                  <FontAwesomeIcon
                    icon={faCalculator}
                    style={{
                      fontSize: 25,
                    }}
                  />
                  <Typography>Contabilidad</Typography>
                </Stack>
              </NavLink> */}
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Collapse>
    </div>
  );
};

export default Administracion;
