import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Button,
	Paper,
	Typography,
	MenuItem,
	InputLabel,
	Grid,
	FormControl,
	Select
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../../api/Account';
import { DataContext } from '../../../context/DataContext';
import {
	getCommunitiesByMunAsync,
	getMunicipalitiesByDeptopAsync,
	getOnlyDepartmentListAsync
} from '../../../api/Locations';
import { simpleMessage, validateCedula } from '../../../helpers/Helpers';

import { getClientByIdAsync, updateClientAsync } from '../../../api/Clients';

const ClientDetails = ({ id, setClientName }) => {
	const token = getToken();

	const [departmentList, setDepartmentList] = useState([]);
	const [selectedDepartment, setselectedDepartment] = useState('');

	const [MunicipalitiesList, setMunicipalitiesList] = useState([]);
	const [selectedMunicipality, setselectedMunicipality] = useState('');

	const [communitiesList, setCommunitiesList] = useState([]);
	const [selectedCommunity, setselectedCommunity] = useState('');

	const [firstName, setFirstName] = useState('');
	const [secondName, setSecondName] = useState('');
	const [lastName, setLastName] = useState('');
	const [secondLastName, setSecondLastName] = useState('');
	const [identification, setIdentification] = useState('');
	const [phone, setPhone] = useState('');

	const [sex, setSex] = useState('');
	const [civilStatus, setCivilStatus] = useState('');

	const [address, setAddress] = useState('');
	const [profession, setProfession] = useState('');

	const [company, setCompany] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');

	const [actividadEconomica, setActividadEconomica] = useState('');

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getClientByIdAsync(token, id);

			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			const resultDepto = await getOnlyDepartmentListAsync(token);

			if (!resultDepto.statusResponse) {
				setIsLoading(false);
				if (resultDepto.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(resultDepto.error, 'error');
				return;
			}

			setDepartmentList(resultDepto.data.result);

			const resultMun = await getMunicipalitiesByDeptopAsync(
				token,
				result.data.result.community.municipality.department.id
			);
			if (!resultMun.statusResponse) {
				setIsLoading(false);
				if (resultMun.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(resultMun.error, 'error');
				return;
			}
			setMunicipalitiesList(resultMun.data.result);

			const resultComm = await getCommunitiesByMunAsync(
				token,
				result.data.result.community.municipality.id
			);
			if (!resultComm.statusResponse) {
				setIsLoading(false);
				if (resultComm.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(resultComm.error, 'error');
				return;
			}

			setCommunitiesList(resultComm.data.result);

			setClientName(result.data.result.fullName);
			setFirstName(result.data.result.firstName);
			setSecondName(result.data.result.secondName);
			setLastName(result.data.result.lastName);
			setSecondLastName(result.data.result.secondLastName);
			setPhone(result.data.result.phone);
			setIdentification(result.data.result.identification);
			setSex(result.data.result.sex === 'MASCULINO' ? 0 : 1);
			setCivilStatus(getCivilStatus(result.data.result.civilStatus));

			setselectedDepartment(result.data.result.community.municipality.department.id);
			setselectedMunicipality(result.data.result.community.municipality.id);
			setselectedCommunity(result.data.result.community.id);
			setAddress(result.data.result.address);

			setActividadEconomica(result.data.result.actividadEconomica);
			setCompany(result.data.result.company);
			setCompanyAddress(result.data.result.companyAddress);
			setProfession(result.data.result.profesion);
			setIsLoading(false);
		})();
	}, []);

	const handleChangeSex = (event) => {
		setSex(event.target.value);
	};

	const handleChangeCivilStatus = (event) => {
		setCivilStatus(event.target.value);
	};

	const getCivilStatus = (data) => {
		if (data === 'SOLTERO' || data === 'SOLTERA') {
			return 0;
		} else if (data === 'CASADO' || data === 'CASADA') {
			return 1;
		} else {
			return 2;
		}
	};

	const handleChangeDepartment = async (event) => {
		setMunicipalitiesList([]);
		setCommunitiesList([]);
		setselectedDepartment(event.target.value);

		if (event.target.value !== '') {
			const result = await getMunicipalitiesByDeptopAsync(token, event.target.value);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setMunicipalitiesList(result.data.result);
		} else {
			setMunicipalitiesList([]);
		}
	};

	const handleChangeMunicipality = async (event) => {
		setCommunitiesList([]);
		setselectedMunicipality(event.target.value);

		if (event.target.value !== '') {
			const result = await getCommunitiesByMunAsync(token, event.target.value);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setCommunitiesList(result.data.result);
		} else {
			setCommunitiesList([]);
		}
	};

	const handleChangeComunity = (event) => {
		setselectedCommunity(event.target.value);
	};

	const saveChanges = async () => {
		if (validate()) {
			const datos = {
				id,
				firstName: firstName.trim(),
				secondName: secondName.trim(),
				lastName: lastName.trim(),
				secondLastName: secondLastName.trim(),
				identification: identification.trim(),
				phone: phone.trim(),
				sex: sex === 0 ? 'MASCULINO' : 'FEMENINO',
				civilStatus:
					civilStatus === 0
						? sex === 0
							? 'SOLTERO'
							: 'SOLTERA'
						: civilStatus === 1
						? sex === 0
							? 'CASADO'
							: 'CASADA'
						: 'UNION LIBRE',
				idCommunity: selectedCommunity,
				address: address.trim(),
				company: company.trim(),
				companyAddress: companyAddress.trim(),
				actividadEconomica: actividadEconomica.trim(),
				profesion: profession.trim()
			};

			setIsLoading(true);
			const result = await updateClientAsync(token, datos);
			if (!result.statusResponse) {
				setIsLoading(false);

				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}

				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);
			setIsEdit(false);
			simpleMessage('Cliente actualizado...!', 'success');
		}
	};

	const validate = () => {
		let isValid = true;
		if (isEmpty(firstName)) {
			simpleMessage('Debe ingresar un nombre', 'error');
			return (isValid = false);
		}

		if (isEmpty(identification)) {
			simpleMessage('Debe ingresar una cedula', 'error');
			return (isValid = false);
		}

		if (!validateCedula(identification)) {
			simpleMessage('Debe ingresar una cedula valida', 'error');
			return (isValid = false);
		}

		if (isEmpty(phone)) {
			simpleMessage('Debe ingresar un telefono', 'error');
			return (isValid = false);
		}

		if (sex === '') {
			simpleMessage('Debe seleccionar un sexo', 'error');
			return (isValid = false);
		}

		if (civilStatus === '') {
			simpleMessage('Debe seleccionar un estado civil', 'error');
			return (isValid = false);
		}

		if (selectedCommunity === '') {
			simpleMessage('Debe seleccionar una comunidad', 'error');
			return (isValid = false);
		}

		if (isEmpty(address)) {
			simpleMessage('error', 'Debe ingresar una direccion', 'error');
			return (isValid = false);
		}

		if (isEmpty(actividadEconomica)) {
			simpleMessage('Debe ingresar una actividad economica', 'error');
			return (isValid = false);
		}
		return isValid;
	};

	return (
		<div>
			<Stack spacing={2}>
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						alignItems="center"
						justifyContent={'space-between'}
					>
						<Typography color={'#00a152'} variant="h6" fontWeight={'bold'}>
							Datos del Cliente
						</Typography>

						<Button
							onClick={() => {
								setIsEdit(!isEdit);
							}}
							style={{
								color: '#ffc400',
								borderColor: '#ffc400'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faPenToSquare}
							/>
							Editar
						</Button>
					</Stack>

					<hr />

					<Grid container spacing={2}>
						<Grid item sm={6}>
							<TextField
								required
								fullWidth
								onChange={(e) => setFirstName(e.target.value.toUpperCase())}
								style={{ marginTop: 20 }}
								value={firstName}
								label={'Primer Nombre'}
								disabled={!isEdit}
							/>

							<TextField
								fullWidth
								onChange={(e) => setSecondName(e.target.value.toUpperCase())}
								style={{ marginTop: 20 }}
								value={secondName}
								label={'Segundo Nombre'}
								disabled={!isEdit}
							/>
							<TextField
								required
								fullWidth
								onChange={(e) => setLastName(e.target.value.toUpperCase())}
								style={{ marginTop: 20 }}
								value={lastName}
								label={'Primer apellido'}
								disabled={!isEdit}
							/>
							<TextField
								fullWidth
								onChange={(e) => setSecondLastName(e.target.value.toUpperCase())}
								style={{ marginTop: 20 }}
								value={secondLastName}
								label={'Segundo Apellido'}
								disabled={!isEdit}
							/>
						</Grid>

						<Grid item sm={6}>
							<TextField
								fullWidth
								required
								onChange={(e) => setPhone(e.target.value.toUpperCase())}
								style={{ marginTop: 20 }}
								value={phone}
								label={'Telefono'}
								disabled={!isEdit}
							/>
							<TextField
								required
								fullWidth
								onChange={(e) => setIdentification(e.target.value.toUpperCase())}
								style={{ marginTop: 20 }}
								value={identification}
								label={'Cedula: 000-000000-0000X'}
								disabled={!isEdit}
							/>
							<FormControl
								fullWidth
								disabled={!isEdit}
								style={{ textAlign: 'left', marginTop: 20 }}
							>
								<InputLabel id="demo-simple-select-standard-label">Sexo</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={handleChangeSex}
									value={sex}
									label="Sexo"
								>
									<MenuItem value={0}>MASCULINO</MenuItem>
									<MenuItem value={1}>FEMENINO</MenuItem>
								</Select>
							</FormControl>

							<FormControl
								disabled={!isEdit}
								fullWidth
								style={{ textAlign: 'left', marginTop: 20 }}
							>
								<InputLabel id="demo-simple-select-standard-label">
									Estado Civil
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={handleChangeCivilStatus}
									value={civilStatus}
									label="Estado Civil"
								>
									<MenuItem value={0}>
										{sex === 0 ? 'SOLTERO' : 'SOLTERA'}
									</MenuItem>
									<MenuItem value={1}>{sex === 0 ? 'CASADO' : 'CASADA'}</MenuItem>
									<MenuItem value={2}>UNION LIBRE</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Paper>

				<Paper style={{ textAlign: 'center', padding: 20 }}>
					<Typography variant="h6" color={'#1c54b2'} style={{ textAlign: 'left' }}>
						Ubicacion del Cliente
					</Typography>
					<hr />
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="space-between"
						spacing={2}
					>
						<FormControl
							disabled={!isEdit}
							fullWidth
							style={{ marginTop: 20 }}
							required
						>
							<InputLabel id="demo-simple-select-standard-label">
								Departamento
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={selectedDepartment}
								onChange={handleChangeDepartment}
								label="Departamento"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Departamento</em>
								</MenuItem>
								{departmentList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl
							disabled={!isEdit}
							fullWidth
							style={{ marginTop: 20 }}
							required
						>
							<InputLabel id="demo-simple-select-standard-label">
								Municipio
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={selectedMunicipality}
								onChange={handleChangeMunicipality}
								label="Municipio"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Municipio</em>
								</MenuItem>
								{MunicipalitiesList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl
							disabled={!isEdit}
							fullWidth
							style={{ marginTop: 20 }}
							required
						>
							<InputLabel id="demo-simple-select-standard-label">
								Comunidad
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={selectedCommunity}
								onChange={handleChangeComunity}
								label="Comunidad"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione una Comunidad</em>
								</MenuItem>
								{communitiesList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Stack>

					<TextField
						required
						fullWidth
						multiline
						onChange={(e) => setAddress(e.target.value.toUpperCase())}
						style={{ marginTop: 20 }}
						value={address}
						label={'Direccion'}
						disabled={!isEdit}
					/>
				</Paper>

				<Paper style={{ textAlign: 'center', padding: 20 }}>
					<Typography variant="h6" color={'#1c54b2'} style={{ textAlign: 'left' }}>
						Actividad Economica del Cliente
					</Typography>
					<hr />
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="space-between"
						spacing={2}
					>
						<TextField
							required
							fullWidth
							onChange={(e) => setProfession(e.target.value.toUpperCase())}
							value={profession}
							label={'Profesion'}
							disabled={!isEdit}
						/>
						<TextField
							required
							fullWidth
							onChange={(e) => setActividadEconomica(e.target.value.toUpperCase())}
							value={actividadEconomica}
							label={'Actividad Economica'}
							disabled={!isEdit}
						/>
						<TextField
							required
							fullWidth
							onChange={(e) => setCompany(e.target.value.toUpperCase())}
							value={company}
							label={'Centro de trabajo'}
							disabled={!isEdit}
						/>
					</Stack>
					<TextField
						required
						fullWidth
						multiline
						onChange={(e) => setCompanyAddress(e.target.value.toUpperCase())}
						style={{ marginTop: 20 }}
						value={companyAddress}
						label={'Direccion del trabajo'}
						disabled={!isEdit}
					/>
				</Paper>

				<Button
					variant="outlined"
					startIcon={<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />}
					fullWidth
					onClick={() => saveChanges()}
					style={{
						marginTop: 30,
						color: '#00a152',
						borderColor: '#00a152'
					}}
					size="large"
					disabled={!isEdit}
				>
					Guardar Cambios
				</Button>
			</Stack>
		</div>
	);
};

export default ClientDetails;
