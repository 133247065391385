import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getSaldoCarteraAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import {
	getGestoresAsync,
	getGestoresBySucursalAccessAsync,
	getSucursalByUserAsync
} from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';

const SaldosCartera = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');
	const [sucursalList, setSucursalList] = useState([]);

	const [gestorName, setGestorName] = useState('');
	const [gestorList, setGestorList] = useState([]);

	const compRef = useRef();
	const { selectedSucursal, selectedGestor } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				gestorId: selectedGestor === 't' ? 0 : selectedGestor,
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal
			};

			setIsLoading(true);
			const result = await getSaldoCarteraAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(
			selectedSucursal === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedSucursal)
		);

		let gestores = await getGestores(selectedSucursal);

		if (selectedGestor === 't') {
			setGestorName('Todos');
		} else {
			let name = gestores.filter((item) => item.id.toString() === selectedGestor);

			setGestorName(name[0].fullName);
		}
		return result.data.result;
	};

	const getGestores = async (sucursal) => {
		setIsLoading(true);
		const result =
			sucursal === 't'
				? await getGestoresBySucursalAccessAsync(token)
				: await getGestoresAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setGestorList(result.data.result);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 5mm; }
`;

	let totalAtrasoConsol = 0;
	let totalVencidoConsol = 0;
	let totalCapitalConsol = 0;

	let totalSaldoCapitalConsol = 0;
	let totalSaldoICConsol = 0;
	let totalSaldoConsol = 0;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			const dataGestor = gestorList.filter((gestor) => gestor.id === item.gestor);
			let gestor = dataGestor[0];
			let obj = {
				idCredito: item.id,
				cliente: item.fullName,
				direccion: item.address,
				telefono: item.phone,
				montoEntregado: item.montoEntregado,
				tipo: item.tipo,
				fechaEntrega: moment(item.fechaEntrega).format('L'),
				fechaVencimiento: moment(item.fechaVencimiento).format('L'),
				atraso: item.atraso,
				saldoCapital: item.saldoCapital,
				saldoInteresCorriente: item.saldoIC,
				saldoTotal: item.saldoTotal,
				gestor: gestor.fullName,
				sucursal: item.sucursal
			};
			exp.push(obj);
		});

		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'saldosCartera');
		XLSX.writeFile(bk, 'SaldosCartera.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Saldos Cartera
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user} - {rol}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							{sucursalList.map((suc) => {
								const sSucursal = data.filter(
									(c) => c.sucursal === suc.description
								);

								return (
									<Stack key={suc.description}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'left'
												}}
												fontSize={11}
											>
												Sucursal:
											</Typography>
											<Typography
												sx={{
													textAlign: 'left'
												}}
												fontSize={11}
											>
												{`${suc.description}`}
											</Typography>
										</Stack>
										{gestorList.map((gestor) => {
											const cGestor = sSucursal.filter(
												(c) => c.gestor === gestor.id
											);
											let totalCapital = 0;
											let totalAtraso = 0;
											let totalSaldoCapital = 0;
											let totalSaldoIC = 0;
											let totalSaldo = 0;
											let totalV = 0;

											cGestor.map((c) => {
												totalCapital += c.montoEntregado;
												totalSaldoCapital += c.saldoCapital;
												totalSaldoIC += c.saldoIC;
												totalSaldo += c.saldoTotal;
												if (c.tipo === 'M') {
													totalAtraso += c.atraso;
												}
												if (c.tipo === 'V') {
													totalV += c.saldoTotal;
												}
											});

											totalAtrasoConsol += totalAtraso;
											totalCapitalConsol += totalCapital;
											totalSaldoCapitalConsol += totalSaldoCapital;
											totalSaldoICConsol += totalSaldoIC;
											totalSaldoConsol += totalSaldoCapital + totalSaldoIC;
											totalVencidoConsol += totalV;
											return isEmpty(cGestor) ? (
												<></>
											) : (
												<Stack key={gestor.id}>
													<Stack direction="row" spacing={1}>
														<Typography
															sx={{
																color: '#2196f3',
																textAlign: 'left'
															}}
															fontSize={11}
														>
															Gestor:
														</Typography>
														<Typography
															sx={{
																textAlign: 'left'
															}}
															fontSize={11}
														>
															{gestor.fullName}
														</Typography>
													</Stack>

													<Divider />

													<TableContainer>
														<Table
															aria-label="simple table"
															size="small"
														>
															<TableHead>
																<TableRow>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		#
																	</TableCell>

																	<TableCell
																		align="left"
																		className="text-primary fw-bold"
																	>
																		Nombre Completo
																	</TableCell>
																	<TableCell
																		align="left"
																		className="text-primary fw-bold"
																	>
																		Direccion
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Telefono
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Desembolso
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Tipo
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Entrega
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Vence
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Atraso
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		S.Capital
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		Saldo I.C.
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																	>
																		S.Total
																	</TableCell>
																</TableRow>
															</TableHead>

															<TableBody>
																{cGestor.map((row) => {
																	return (
																		<TableRow key={row.id}>
																			<TableCell
																				align="center"
																				component="th"
																				scope="row"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{row.id}
																			</TableCell>

																			<TableCell
																				align="left"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{row.fullName}
																			</TableCell>
																			<TableCell
																				align="left"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{row.address}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{row.phone}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					row.montoEntregado
																				)}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{row.tipo}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{moment(
																					row.fechaEntrega
																				).format('L')}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				$
																				{moment(
																					row.fechaVencimiento
																				).format('L')}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					row.atraso
																				)}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					row.saldoCapital
																				)}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					row.saldoIC
																				)}
																			</TableCell>

																			<TableCell
																				align="center"
																				style={{
																					fontSize: 11
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					row.saldoTotal
																				)}
																			</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</Table>
													</TableContainer>

													<hr />
													<Stack
														direction="row"
														flex="row"
														justifyContent="space-around"
													>
														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total Créditos
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{cGestor.length}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total Capital
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalCapital)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total Mora
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{`${new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalAtraso)} = ${(
																	(totalAtraso / totalSaldo) *
																	100
																).toFixed(2)} %`}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total Vencido
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{`${new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalV)} = ${(
																	(totalV / totalSaldo) *
																	100
																).toFixed(2)} %`}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Saldo Capital Total
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalSaldoCapital)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Saldo I.C. Total
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalSaldoIC)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Saldo Total
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalSaldo)}
															</Typography>
														</Stack>
													</Stack>
													<hr />
												</Stack>
											);
										})}
									</Stack>
								);
							})}
						</>
					)}

					{selectedGestor !== 't' ? (
						<></>
					) : (
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center'
								}}
							>
								Consolidado
							</Typography>

							<hr />

							<Stack direction="row" flex="row" justifyContent="space-around">
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Créditos
									</Typography>
									<Typography style={{ fontSize: 11 }}>{data.length}</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Capital
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCapitalConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Mora
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{`${new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalAtrasoConsol)} = ${(
											(totalAtrasoConsol / totalSaldoConsol) *
											100
										).toFixed(2)} %`}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Vencido
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{`${new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalVencidoConsol)} = ${(
											(totalVencidoConsol / totalSaldoConsol) *
											100
										).toFixed(2)} %`}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Saldo Capital Total
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalSaldoCapitalConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Saldo I.C. Total
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalSaldoICConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Saldo
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalSaldoConsol)}
									</Typography>
								</Stack>
							</Stack>
							<hr />
						</Stack>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Saldos Cartera'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user} - {rol}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<hr />
						{isEmpty(data) ? (
							<NoData />
						) : (
							<>
								{sucursalList.map((suc) => {
									const sSucursal = data.filter(
										(c) => c.sucursal === suc.description
									);

									return (
										<Stack key={suc.description}>
											<Stack direction="row" spacing={1}>
												<Typography
													sx={{
														color: '#2196f3',
														textAlign: 'left'
													}}
													fontSize={11}
												>
													Sucursal:
												</Typography>
												<Typography
													sx={{
														textAlign: 'left'
													}}
													fontSize={11}
												>
													{`${suc.description}`}
												</Typography>
											</Stack>
											{gestorList.map((gestor) => {
												const cGestor = data.filter(
													(c) => c.gestor === gestor.id
												);
												let totalCapital = 0;
												let totalAtraso = 0;
												let totalSaldoCapital = 0;
												let totalSaldoIC = 0;
												let totalSaldo = 0;
												let totalV = 0;

												cGestor.map((c) => {
													totalCapital += c.montoEntregado;
													totalAtraso += c.atraso;
													totalSaldoCapital += c.saldoCapital;
													totalSaldoIC += c.saldoIC;
													totalSaldo += c.saldoTotal;
													if (c.tipo === 'V') {
														totalV += c.saldoTotal;
													}
												});

												return isEmpty(cGestor) ? (
													<></>
												) : (
													<Stack key={gestor.id}>
														<Stack direction="row" spacing={1}>
															<Typography
																sx={{
																	color: '#2196f3',
																	textAlign: 'left'
																}}
																fontSize={11}
															>
																Gestor:
															</Typography>
															<Typography
																sx={{
																	textAlign: 'left'
																}}
																fontSize={11}
															>
																{gestor.fullName}
															</Typography>
														</Stack>

														<Divider />

														<TableContainer>
															<Table
																aria-label="simple table"
																size="small"
															>
																<TableHead>
																	<TableRow>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			#
																		</TableCell>

																		<TableCell
																			align="left"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Nombre Completo
																		</TableCell>
																		<TableCell
																			align="left"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Direccion
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Telefono
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Desembolso
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Tipo
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Entrega
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Vence
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Atraso
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			S.Capital
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Saldo I.C.
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			S.Total
																		</TableCell>
																	</TableRow>
																</TableHead>

																<TableBody>
																	{cGestor.map((row) => {
																		return (
																			<TableRow key={row.id}>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{row.id}
																				</TableCell>

																				<TableCell
																					align="left"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{row.fullName}
																				</TableCell>
																				<TableCell
																					align="left"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{row.address}
																				</TableCell>
																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{row.phone}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.montoEntregado
																					)}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{row.tipo}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{moment(
																						row.fechaEntrega
																					).format('L')}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{moment(
																						row.fechaVencimiento
																					).format('L')}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.atraso
																					)}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.saldoCapital
																					)}
																				</TableCell>

																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.saldoIC
																					)}
																				</TableCell>
																				<TableCell
																					align="center"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.saldoTotal
																					)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>

														<hr />
														<Stack
															direction="row"
															flex="row"
															justifyContent="space-around"
														>
															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total Créditos
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{cGestor.length}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total Capital
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalCapital)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total Mora
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{`${new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(
																		totalAtrasoConsol
																	)} = ${(
																		(totalAtrasoConsol /
																			totalSaldoConsol) *
																		100
																	).toFixed(2)} %`}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total Vencido
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{`${new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(
																		totalVencidoConsol
																	)} = ${(
																		(totalVencidoConsol /
																			totalSaldoConsol) *
																		100
																	).toFixed(2)} %`}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Saldo Capital Total
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(
																		totalSaldoCapitalConsol
																	)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Saldo I.C. Total
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalSaldoICConsol)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Saldo Total
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalSaldo)}
																</Typography>
															</Stack>
														</Stack>
														<hr />
													</Stack>
												);
											})}
										</Stack>
									);
								})}
							</>
						)}

						{gestorList.length === 1 ? (
							<></>
						) : (
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'center'
									}}
								>
									Consolidado
								</Typography>

								<hr />

								<Stack direction="row" flex="row" justifyContent="space-around">
									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Total Créditos
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{data.length}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Total Capital
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalCapitalConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Total Mora
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{`${new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalAtrasoConsol)} = ${(
												(totalAtrasoConsol / totalSaldoConsol) *
												100
											).toFixed(2)} %`}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Total Vencido
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{`${new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalVencidoConsol)} = ${(
												(totalVencidoConsol / totalSaldoConsol) *
												100
											).toFixed(2)} %`}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Saldo Capital Total
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalSaldoCapitalConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Saldo I.C. Total
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalSaldoICConsol)}
										</Typography>
									</Stack>

									<Stack textAlign="center">
										<Typography
											style={{
												fontWeight: 'bold',
												color: '#03a9f4',
												fontSize: 11
											}}
										>
											Total Saldo
										</Typography>
										<Typography style={{ fontSize: 11 }}>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalSaldoConsol)}
										</Typography>
									</Stack>
								</Stack>
								<hr />
							</Stack>
						)}
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default SaldosCartera;
