import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Container,
	Button,
	Paper,
	Typography,
	MenuItem,
	InputLabel,
	FormControl,
	Select,
	Switch,
	FormControlLabel,
	Checkbox,
	Divider
} from '@mui/material';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';
import { getRuta, simpleMessage } from '../../../helpers/Helpers';
import { deleteToken, deleteUserData, getToken } from '../../../api/Account';
import { addRolAsync, getRolListAsync } from '../../../api/Rols';

const AddRol = () => {
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	const { setIsLoading, setIsLogged, setIsUnautorized } = useContext(DataContext);

	const [rolName, setRolName] = useState('');
	const [isFullAccess, setIsFullAccess] = useState(false);
	const [rolesList, setRolesList] = useState([]);

	const [nivelJerarquico, setNivelJerarquico] = useState(0);

	//Client module
	const [clientVer, setClientVer] = useState(false);
	const [clientCreate, setClientCreate] = useState(false);
	const [clientUpdate, setClientUpdate] = useState(false);
	const [clientDelete, setClientDelete] = useState(false);

	//Credit Module
	const [creditVer, setCreditVer] = useState(false);
	const [creditCreate, setCreditCreate] = useState(false);
	const [creditUpdate, setCreditUpdate] = useState(false);
	const [creditRegen, setCreditRegen] = useState(false);
	const [creditDelete, setCreditDelete] = useState(false);
	const [creditLiquidate, setCreditLiquidate] = useState(false);
	const [paymentUpdate, setPaymentUpdate] = useState(false);
	const [printPagare, setPrintPagare] = useState(false);

	//Admon Module
	const [cashMovVer, setCashMovVer] = useState(false);
	const [cashMovCreate, setCashMovCreate] = useState(false);
	const [cashMovDelete, setCashMovDelete] = useState(false);
	const [admonVer, setAdmonVer] = useState(false);
	const [admonCreate, setAdmonCreate] = useState(false);
	const [cajaVer, setCajaVer] = useState(false);
	const [cajaCreate, setCajaCreate] = useState(false);

	//Report Module
	const [rClients, setRClients] = useState(false);
	const [rCredXGest, setRCredCgest] = useState(false);
	const [rCobDiario, setRCobDiario] = useState(false);
	const [recuperac, setRecuperac] = useState(false);
	const [rDesembolso, setRDesembolso] = useState(false);
	const [rSalCart, setRSalCart] = useState(false);
	const [rCredVenc, setRCredVenc] = useState(false);
	const [rVencProy, setRVencProy] = useState(false);
	const [rCredCanc, setRCredCanc] = useState(false);
	const [rAccountSts, setRAccountSts] = useState(false);
	const [rBoxClose, setRBoxClose] = useState(false);
	const [rResultSts, setRResultSts] = useState(false);
	const [rConami, setRConami] = useState(false);

	//Securiry Module
	const [securityVer, setSecurityVer] = useState(false);
	const [securityCreate, setSecurityCreate] = useState(false);
	const [securityUpdate, setSecurityUpdate] = useState(false);
	const [securityDelete, setSecurityDelete] = useState(false);
	const [verSchedule, setVerSchedule] = useState(false);
	const [editSchedule, setEditSchedule] = useState(false);

	//Configuration Module
	const [confVer, setConfVer] = useState(false);
	const [confCreate, setConfCreate] = useState(false);
	const [confUpdate, setConfUpdate] = useState(false);
	const [confDelete, setConfDelete] = useState(false);

	const handleChangeFullAccess = () => {
		setIsFullAccess(!isFullAccess);

		//Clients
		setClientVer(!isFullAccess);
		setClientCreate(!isFullAccess);
		setClientUpdate(!isFullAccess);
		setClientDelete(!isFullAccess);

		//Credits
		setCreditVer(!isFullAccess);
		setCreditCreate(!isFullAccess);
		setCreditUpdate(!isFullAccess);
		setCreditRegen(!isFullAccess);
		setCreditDelete(!isFullAccess);
		setCreditLiquidate(!isFullAccess);
		setPaymentUpdate(!isFullAccess);
		setPrintPagare(!isFullAccess);

		//Admon
		setCashMovVer(!isFullAccess);
		setCashMovCreate(!isFullAccess);
		setCashMovDelete(!isFullAccess);
		setAdmonVer(!isFullAccess);
		setAdmonCreate(!isFullAccess);
		setCajaVer(!isFullAccess);
		setCajaCreate(!isFullAccess);

		//Reports
		setRClients(!isFullAccess);
		setRCredCgest(!isFullAccess);
		setRCobDiario(!isFullAccess);
		setRecuperac(!isFullAccess);
		setRDesembolso(!isFullAccess);
		setRSalCart(!isFullAccess);
		setRCredVenc(!isFullAccess);
		setRVencProy(!isFullAccess);
		setRCredCanc(!isFullAccess);
		setRAccountSts(!isFullAccess);
		setRBoxClose(!isFullAccess);
		setRResultSts(!isFullAccess);
		setRConami(!isFullAccess);

		//Security
		setSecurityVer(!isFullAccess);
		setSecurityCreate(!isFullAccess);
		setSecurityUpdate(!isFullAccess);
		setSecurityDelete(!isFullAccess);
		setVerSchedule(!isFullAccess);
		setEditSchedule(!isFullAccess);

		//Settings
		setConfVer(!isFullAccess);
		setConfCreate(!isFullAccess);
		setConfUpdate(!isFullAccess);
		setConfDelete(!isFullAccess);
	};

	const saveRol = async () => {
		if (rolName === '') {
			simpleMessage('Ingrese una nombre al nuevo rol', 'error');
			return;
		}

		const datos = {
			permissions: [
				//Clients
				{
					description: 'CLIENTS VER',
					IsEnable: clientVer
				},
				{
					description: 'CLIENTS CREATE',
					IsEnable: clientCreate
				},
				{
					description: 'CLIENTS UPDATE',
					IsEnable: clientUpdate
				},
				{
					description: 'CLIENTS DELETE',
					IsEnable: clientDelete
				},

				//Credits
				{
					description: 'CREDITS VER',
					IsEnable: creditVer
				},
				{
					description: 'CREDITS CREATE',
					IsEnable: creditCreate
				},
				{
					description: 'CREDITS UPDATE',
					IsEnable: creditUpdate
				},
				{
					description: 'CREDITS REGEN',
					IsEnable: creditRegen
				},
				{
					description: 'CREDITS DELETE',
					IsEnable: creditDelete
				},
				{
					description: 'CREDIT LIQUIDATE',
					IsEnable: creditLiquidate
				},
				{
					description: 'PAYMENT UPDATE',
					IsEnable: paymentUpdate
				},
				{
					description: 'PRINT PAGARE',
					IsEnable: printPagare
				},

				//Admon
				{
					description: 'CASHMOV VER',
					IsEnable: cashMovVer
				},
				{
					description: 'CASHMOV CREATE',
					IsEnable: cashMovCreate
				},
				{
					description: 'CASHMOV DELETE',
					IsEnable: cashMovDelete
				},

				{
					description: 'ADMON VER',
					IsEnable: admonVer
				},
				{
					description: 'ADMON CREATE',
					IsEnable: admonCreate
				},
				{
					description: 'CAJA VER',
					IsEnable: cajaVer
				},
				{
					description: 'CAJA CREATE',
					IsEnable: false
				},
				{
					description: 'CASHMOV DELETE',
					IsEnable: cashMovDelete
				},

				//Modulo Reportes
				{
					description: 'REPORTS VER',
					IsEnable: true
				},
				{
					description: 'RCLIENTS',
					IsEnable: rClients
				},
				{
					description: 'RCREDXGEST',
					IsEnable: rCredXGest
				},
				{
					description: 'RCOBDIARIO',
					IsEnable: rCobDiario
				},
				{
					description: 'RECUPERAC',
					IsEnable: recuperac
				},
				{
					description: 'RDESEMBOLSO',
					IsEnable: rDesembolso
				},
				{
					description: 'RSALCART',
					IsEnable: rSalCart
				},
				{
					description: 'RCREDVENC',
					IsEnable: rCredVenc
				},
				{
					description: 'RVENCPROY',
					IsEnable: rVencProy
				},
				{
					description: 'RCREDCANC',
					IsEnable: rCredCanc
				},
				{
					description: 'RACCOUNTSTS',
					IsEnable: rAccountSts
				},
				{
					description: 'RBOXCLOSE',
					IsEnable: rBoxClose
				},
				{
					description: 'RRESULTSTS',
					IsEnable: rResultSts
				},
				{
					description: 'KONAMI VER',
					IsEnable: rConami
				},

				//Modulo Security
				{
					description: 'SECURITY VER',
					IsEnable: securityVer
				},
				{
					description: 'SECURITY  CREATE',
					IsEnable: securityCreate
				},
				{
					description: 'SECURITY UPDATE',
					IsEnable: securityUpdate
				},
				{
					description: 'SECURITY DELETE',
					IsEnable: securityDelete
				},
				{
					description: 'VER HORARIOS',
					IsEnable: verSchedule
				},
				{
					description: 'EDITAR HORARIOS',
					IsEnable: editSchedule
				},

				//Modulo Settings
				{
					description: 'CONFIG VER',
					IsEnable: confVer
				},
				{
					description: 'CONFIG CREATE',
					IsEnable: confCreate
				},
				{
					description: 'CONFIG UPDATE',
					IsEnable: confUpdate
				},
				{
					description: 'CONFIG DELETE',
					IsEnable: confDelete
				}
			],
			roleName: rolName,
			isFullAccess,
			nivelJerarquico
		};

		setIsLoading(true);
		const result = await addRolAsync(token, datos);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'eX01') {
				deleteUserData();
				deleteToken();
				setIsLogged(false);
				return;
			}

			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}

			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		clearData();
		simpleMessage('Rol Agregado...!', 'success');
	};

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getRolListAsync(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);
			setRolesList(result.data.result);

			setIsLoading(false);
		})();
	}, []);

	const clearData = () => {
		setRolName('');
		setIsFullAccess(true);
		handleChangeFullAccess();
		setNivelJerarquico(0);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/rols`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Typography color={'#00a152'} variant="h4" fontWeight={'bold'}>
							Agregar Rol
						</Typography>
					</Stack>

					<hr />

					<Typography variant="h6" color={'#1c54b2'} style={{ textAlign: 'left' }}>
						Configuraciones del Nuevo Rol
					</Typography>

					<Paper
						style={{
							padding: 20,
							textAlign: 'center'
						}}
					>
						<Stack
							spacing={2}
							direction={{ xs: 'column', sm: 'row' }}
							justifyContent="space-between"
						>
							<TextField
								fullWidth
								style={{ maxWidth: 600 }}
								onChange={(e) => setRolName(e.target.value.toUpperCase())}
								label={'Nombre rol'}
								value={rolName}
							/>

							<FormControl fullWidth required style={{ maxWidth: 600 }}>
								<InputLabel id="demo-simple-select-standard-label">
									Seleccione un rol superior
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={nivelJerarquico}
									onChange={(e) => setNivelJerarquico(e.target.value)}
									label="Seleccione un rol superior"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione un rol superior</em>
									</MenuItem>
									{rolesList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.rolName}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Stack>

						<Typography
							variant="h6"
							fontWeight={'bold'}
							style={{
								color: '#2979ff'
							}}
						>
							Acceso Total al Sistema
						</Typography>

						<Switch
							style={{
								color: isFullAccess ? '#4caf50' : '#f50057'
							}}
							checked={isFullAccess}
							onChange={handleChangeFullAccess}
						/>

						<Stack spacing={2}>
							{/* Clientes */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Clientes
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={clientVer}
												onChange={() => setClientVer(!clientVer)}
											/>
										}
										label="Ver Clientes"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={clientCreate}
												onChange={() => setClientCreate(!clientCreate)}
											/>
										}
										label="Crear Clientes"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												checked={clientUpdate}
												onChange={() => setClientUpdate(!clientUpdate)}
											/>
										}
										label="Actualizar Cliente"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												checked={clientDelete}
												onChange={() => setClientDelete(!clientDelete)}
											/>
										}
										label="Eliminar Cliente"
									/>
								</Stack>
							</Paper>

							{/* Credits */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Créditos
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
									marginTop={1}
									spacing={2}
								>
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Permisos de Crédito
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={creditVer}
														onChange={() => setCreditVer(!creditVer)}
													/>
												}
												label="Ver Creditos"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={creditCreate}
														onChange={() =>
															setCreditCreate(!creditCreate)
														}
													/>
												}
												label="Crear Creditos"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={creditUpdate}
														onChange={() =>
															setCreditUpdate(!creditUpdate)
														}
													/>
												}
												label="Actualizar Credito"
											/>
										</Stack>
									</Paper>

									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Permisos Especiales de Crédito
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'column', md: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={creditRegen}
														onChange={() =>
															setCreditRegen(!creditRegen)
														}
													/>
												}
												label="Regenerar Credito"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={creditLiquidate}
														onChange={() =>
															setCreditLiquidate(!creditLiquidate)
														}
													/>
												}
												label="Liquidar Credito"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={creditDelete}
														onChange={() =>
															setCreditDelete(!creditDelete)
														}
													/>
												}
												label="Eliminar Credito"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={paymentUpdate}
														onChange={() =>
															setPaymentUpdate(!paymentUpdate)
														}
													/>
												}
												label="Cambiar F. Pago"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={printPagare}
														onChange={() =>
															setPrintPagare(!printPagare)
														}
													/>
												}
												label="Imprimir Pagaré"
											/>
										</Stack>
									</Paper>
								</Stack>
							</Paper>

							{/* Admon */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Administración
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
									marginTop={1}
									spacing={2}
								>
									{/* Permisos Caja general */}
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Caja
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={cajaVer}
														onChange={() => setCajaVer(!cajaVer)}
													/>
												}
												label="Ver Caja"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={cajaCreate}
														onChange={() => setCajaCreate(!cajaCreate)}
													/>
												}
												label="Crear Movimiento"
											/>
										</Stack>
									</Paper>

									{/* Permisos Caja Chica */}
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Caja Chica
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={cashMovVer}
														onChange={() => setCashMovVer(!cashMovVer)}
													/>
												}
												label="Ver Caja"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={cashMovCreate}
														onChange={() =>
															setCashMovCreate(!cashMovCreate)
														}
													/>
												}
												label="Crear Movimiento"
											/>
										</Stack>
									</Paper>

									{/* Permisos Contabilidad */}
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Gastos
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={admonVer}
														onChange={() => setAdmonVer(!admonVer)}
													/>
												}
												label="Ver Gastos"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={admonCreate}
														onChange={() =>
															setAdmonCreate(!admonCreate)
														}
													/>
												}
												label="Crear Gastos"
											/>
										</Stack>
									</Paper>
								</Stack>
							</Paper>

							{/* Reports */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Reportes
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rClients}
												onChange={() => setRClients(!rClients)}
											/>
										}
										label="Clientes por Gestor"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rCredXGest}
												onChange={() => setRCredCgest(!rCredXGest)}
											/>
										}
										label="Creditos por Gestor"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rCobDiario}
												onChange={() => setRCobDiario(!rCobDiario)}
											/>
										}
										label="Cobro Diario"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={recuperac}
												onChange={() => setRecuperac(!recuperac)}
											/>
										}
										label="Recuperación"
									/>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rDesembolso}
												onChange={() => setRDesembolso(!rDesembolso)}
											/>
										}
										label="Desembolso"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rSalCart}
												onChange={() => setRSalCart(!rSalCart)}
											/>
										}
										label="Saldos Cartera"
									/>
								</Stack>

								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rCredVenc}
												onChange={() => setRCredVenc(!rCredVenc)}
											/>
										}
										label="Créditos Vencidos"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rVencProy}
												onChange={() => setRVencProy(!rVencProy)}
											/>
										}
										label="Vencimientos Proyectados"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rCredCanc}
												onChange={() => setRCredCanc(!rCredCanc)}
											/>
										}
										label="Créditos Cancelados"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rAccountSts}
												onChange={() => setRAccountSts(!rAccountSts)}
											/>
										}
										label="Estado de Cuenta"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rBoxClose}
												onChange={() => setRBoxClose(!rBoxClose)}
											/>
										}
										label="Cierre de Caja"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rResultSts}
												onChange={() => setRResultSts(!rResultSts)}
											/>
										}
										label="Estado de Resultado"
									/>
								</Stack>

								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={rConami}
												onChange={() => setRConami(!rConami)}
											/>
										}
										label="Reporte Conami"
									/>
								</Stack>
							</Paper>

							{/* Security */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Seguridad
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
									marginTop={1}
									spacing={1}
								>
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Usuarios y Roles
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={securityVer}
														onChange={() =>
															setSecurityVer(!securityVer)
														}
													/>
												}
												label="Ver"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={securityCreate}
														onChange={() =>
															setSecurityCreate(!securityCreate)
														}
													/>
												}
												label="Crear"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={securityUpdate}
														onChange={() =>
															setSecurityUpdate(!securityUpdate)
														}
													/>
												}
												label="Actualizar"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														checked={securityDelete}
														onChange={() =>
															setSecurityDelete(!securityDelete)
														}
													/>
												}
												label="Eliminar"
											/>
										</Stack>
									</Paper>

									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Accesos al Servidor
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-between"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={verSchedule}
														onChange={() =>
															setVerSchedule(!verSchedule)
														}
													/>
												}
												label="Ver Horarios"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														checked={editSchedule}
														onChange={() =>
															setEditSchedule(!editSchedule)
														}
													/>
												}
												label="Editar Horarios"
											/>
										</Stack>
									</Paper>
								</Stack>
							</Paper>

							{/* Configurations */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Configuracion
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={confVer}
												onChange={() => setConfVer(!confVer)}
											/>
										}
										label="Ver Configuraciones"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												checked={confCreate}
												onChange={() => setConfCreate(!confCreate)}
											/>
										}
										label="Crear Configuraciones"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												checked={confUpdate}
												onChange={() => setConfUpdate(!confUpdate)}
											/>
										}
										label="Actualizar Configuraciones"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												checked={confDelete}
												onChange={() => setConfDelete(!confDelete)}
											/>
										}
										label="Eliminar Configuraciones"
									/>
								</Stack>
							</Paper>

							<Button
								fullWidth
								variant="outlined"
								style={{ color: '#2979ff', borderColor: '#2979ff' }}
								startIcon={<FontAwesomeIcon icon={faSave} />}
								onClick={() => saveRol()}
							>
								Agregar Rol
							</Button>
						</Stack>
					</Paper>
				</Paper>
			</Container>
		</div>
	);
};

export default AddRol;
