import React, { useState, useContext } from "react";

import {
  TextField,
  Divider,
  Container,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { Save } from "@mui/icons-material";
import { updateIntCorrienteAsync } from "../../../api/InteresCorriente";

export const EditTasaInteres = ({ selected, setShowModal }) => {
  const { id, intCorriente } = selected;
  const token = getToken();
  const { reload, setReload, setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const [interesC, setInteresC] = useState(intCorriente);

  const setTasaInteres = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString())) {
      setInteresC(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (interesC === "") {
      simpleMessage("Ingrese un valor...", "error");
      return;
    }
    const data = {
      id,
      intCorriente: interesC,
    };

    setIsLoading(true);
    const result = await updateIntCorrienteAsync(token, data);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Tasa de interes Actualizada...!", "success");
    setReload(!reload);
    setShowModal(false);
  };

  return (
    <div>
      <Container style={{ width: 450 }}>
        <Divider />

        <TextField
          fullWidth
          required
          style={{ marginBottom: 20, marginTop: 20 }}
          onChange={(e) => setTasaInteres(e.target.value.toUpperCase())}
          label={"Interes Corriente"}
          value={interesC}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={"editar"}>
                  <IconButton
                    onClick={() => {
                      saveChangesAsync();
                    }}
                  >
                    <Save style={{ color: "#ff5722" }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};
