import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Container,
	Button,
	Paper,
	Typography,
	MenuItem,
	InputLabel,
	FormControl,
	Select,
	Switch,
	FormControlLabel,
	Checkbox,
	Divider
} from '@mui/material';

import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronCircleLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { useNavigate, useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';
import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';
import { getToken } from '../../../api/Account';
import { getRolByIdAsync, getRolListAsync, updateRolAsync } from '../../../api/Rols';

const EditRol = () => {
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();
	const { id } = useParams();

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

	const [rolName, setRolName] = useState('');
	const [isFullAccess, setIsFullAccess] = useState(false);
	const [rolesList, setRolesList] = useState([]);
	const [selectedRol, setSelectedRol] = useState([]);

	const [nivelJerarquico, setNivelJerarquico] = useState(0);

	//Client module
	const [clientVer, setClientVer] = useState(false);
	const [clientCreate, setClientCreate] = useState(false);
	const [clientUpdate, setClientUpdate] = useState(false);
	const [clientDelete, setClientDelete] = useState(false);

	//Credit Module
	const [creditVer, setCreditVer] = useState(false);
	const [creditCreate, setCreditCreate] = useState(false);
	const [creditUpdate, setCreditUpdate] = useState(false);
	const [creditRegen, setCreditRegen] = useState(false);
	const [creditDelete, setCreditDelete] = useState(false);
	const [creditLiquidate, setCreditLiquidate] = useState(false);
	const [paymentUpdate, setPaymentUpdate] = useState(false);
	const [printPagare, setPrintPagare] = useState(false);

	//Admon Module
	const [cashMovVer, setCashMovVer] = useState(false);
	const [cashMovCreate, setCashMovCreate] = useState(false);
	const [cashMovDelete, setCashMovDelete] = useState(false);
	const [admonVer, setAdmonVer] = useState(false);
	const [admonCreate, setAdmonCreate] = useState(false);
	const [cajaVer, setCajaVer] = useState(false);
	const [cajaCreate, setCajaCreate] = useState(false);

	//Report Module
	const [rClients, setRClients] = useState(false);
	const [rCredXGest, setRCredCgest] = useState(false);
	const [rCobDiario, setRCobDiario] = useState(false);
	const [recuperac, setRecuperac] = useState(false);
	const [rDesembolso, setRDesembolso] = useState(false);
	const [rSalCart, setRSalCart] = useState(false);
	const [rCredVenc, setRCredVenc] = useState(false);
	const [rVencProy, setRVencProy] = useState(false);
	const [rCredCanc, setRCredCanc] = useState(false);
	const [rAccountSts, setRAccountSts] = useState(false);
	const [rBoxClose, setRBoxClose] = useState(false);
	const [rResultSts, setRResultSts] = useState(false);
	const [rConami, setRConami] = useState(false);

	//Securiry Module
	const [securityVer, setSecurityVer] = useState(false);
	const [securityCreate, setSecurityCreate] = useState(false);
	const [securityUpdate, setSecurityUpdate] = useState(false);
	const [securityDelete, setSecurityDelete] = useState(false);
	const [verSchedule, setVerSchedule] = useState(false);
	const [editSchedule, setEditSchedule] = useState(false);

	//Configuration Module
	const [confVer, setConfVer] = useState(false);
	const [confCreate, setConfCreate] = useState(false);
	const [confUpdate, setConfUpdate] = useState(false);
	const [confDelete, setConfDelete] = useState(false);

	const [isEdit, setIsEdit] = useState(false);

	const handleChangeFullAccess = () => {
		setIsFullAccess(!isFullAccess);

		//Clients
		setClientVer(!isFullAccess);
		setClientCreate(!isFullAccess);
		setClientUpdate(!isFullAccess);
		setClientDelete(!isFullAccess);

		//Credits
		setCreditVer(!isFullAccess);
		setCreditCreate(!isFullAccess);
		setCreditUpdate(!isFullAccess);
		setCreditRegen(!isFullAccess);
		setCreditDelete(!isFullAccess);
		setCreditLiquidate(!isFullAccess);
		setPaymentUpdate(!isFullAccess);
		setPrintPagare(!isFullAccess);

		//Admon
		setCashMovVer(!isFullAccess);
		setCashMovCreate(!isFullAccess);
		setCashMovDelete(!isFullAccess);
		setAdmonVer(!isFullAccess);
		setAdmonCreate(!isFullAccess);
		setCajaVer(!isFullAccess);
		setCajaCreate(!isFullAccess);

		//Reports
		setRClients(!isFullAccess);
		setRCredCgest(!isFullAccess);
		setRCobDiario(!isFullAccess);
		setRecuperac(!isFullAccess);
		setRDesembolso(!isFullAccess);
		setRSalCart(!isFullAccess);
		setRCredVenc(!isFullAccess);
		setRVencProy(!isFullAccess);
		setRCredCanc(!isFullAccess);
		setRAccountSts(!isFullAccess);
		setRBoxClose(!isFullAccess);
		setRResultSts(!isFullAccess);
		setRConami(!isFullAccess);

		//Security
		setSecurityVer(!isFullAccess);
		setSecurityCreate(!isFullAccess);
		setSecurityUpdate(!isFullAccess);
		setSecurityDelete(!isFullAccess);
		setVerSchedule(!isFullAccess);
		setEditSchedule(!isFullAccess);

		//Settings
		setConfVer(!isFullAccess);
		setConfCreate(!isFullAccess);
		setConfUpdate(!isFullAccess);
		setConfDelete(!isFullAccess);
	};

	const saveRol = async () => {
		if (rolName === '') {
			simpleMessage('Ingrese una nombre al nuevo rol', 'error');
			return;
		}

		selectedRol.rolName = rolName;
		selectedRol.isFullAccess = isFullAccess;
		selectedRol.idRolSuperior = nivelJerarquico;
		selectedRol.permissions.map((item) => {
			switch (item.description) {
				//Clients
				case 'CLIENTS VER':
					item.isEnable = clientVer;
					break;
				case 'CLIENTS CREATE':
					item.isEnable = clientCreate;
					break;
				case 'CLIENTS UPDATE':
					item.isEnable = clientUpdate;
					break;
				case 'CLIENTS DELETE':
					item.isEnable = clientDelete;
					break;

				//Admon
				case 'CASHMOV VER':
					item.isEnable = cashMovVer;
					break;
				case 'CASHMOV CREATE':
					item.isEnable = cashMovCreate;
					break;
				case 'CASHMOV DELETE':
					item.isEnable = cashMovDelete;
					break;

				case 'ADMON VER':
					item.isEnable = admonVer;
					break;
				case 'ADMON CREATE':
					item.isEnable = admonCreate;
					break;
				case 'CAJA VER':
					item.isEnable = cajaVer;
					break;
				case 'CAJA CREATE':
					item.isEnable = cajaCreate;
					break;

				//Credits
				case 'CREDITS VER':
					item.isEnable = creditVer;
					break;
				case 'CREDITS CREATE':
					item.isEnable = creditCreate;
					break;
				case 'CREDITS REGEN':
					item.isEnable = creditRegen;
					break;
				case 'CREDITS UPDATE':
					item.isEnable = creditUpdate;
					break;
				case 'CREDITS DELETE':
					item.isEnable = creditDelete;
					break;
				case 'CREDIT LIQUIDATE':
					item.isEnable = creditLiquidate;
					break;
				case 'PAYMENT UPDATE':
					item.isEnable = paymentUpdate;
					break;
				case 'PRINT PAGARE':
					item.isEnable = printPagare;
					break;

				//Reports
				case 'RCLIENTS':
					item.isEnable = rClients;
					break;

				case 'RCREDXGEST':
					item.isEnable = rCredXGest;
					break;

				case 'RCOBDIARIO':
					item.isEnable = rCobDiario;
					break;

				case 'RECUPERAC':
					item.isEnable = recuperac;
					break;

				case 'RDESEMBOLSO':
					item.isEnable = rDesembolso;
					break;

				case 'RSALCART':
					item.isEnable = rSalCart;
					break;

				case 'RCREDVENC':
					item.isEnable = rCredVenc;
					break;

				case 'RVENCPROY':
					item.isEnable = rVencProy;
					break;

				case 'RCREDCANC':
					item.isEnable = rCredCanc;
					break;

				case 'RACCOUNTSTS':
					item.isEnable = rAccountSts;
					break;

				case 'RBOXCLOSE':
					item.isEnable = rBoxClose;
					break;

				case 'RRESULTSTS':
					item.isEnable = rResultSts;
					break;

				case 'KONAMI VER':
					item.isEnable = rConami;
					break;

				//Security
				case 'SECURITY VER':
					item.isEnable = securityVer;
					break;
				case 'SECURITY CREATE':
					item.isEnable = securityCreate;
					break;
				case 'SECURITY UPDATE':
					item.isEnable = securityUpdate;
					break;
				case 'SECURITY DELETE':
					item.isEnable = securityDelete;
					break;
				case 'VER HORARIOS':
					item.isEnable = verSchedule;
					break;
				case 'EDITAR HORARIOS':
					item.isEnable = editSchedule;
					break;

				//Configurations
				case 'CONFIG VER':
					item.isEnable = confVer;
					break;
				case 'CONFIG CREATE':
					item.isEnable = confCreate;
					break;
				case 'CONFIG UPDATE':
					item.isEnable = confUpdate;
					break;
				case 'CONFIG DELETE':
					item.isEnable = confDelete;
					break;
			}
		});

		setIsLoading(true);
		const result = await updateRolAsync(token, selectedRol);
		if (!result.statusResponse) {
			setIsLoading(false);

			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}

			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		simpleMessage('Rol actualizado...!', 'success');
		setIsEdit(false);
	};

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getRolListAsync(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setRolesList(result.data.result);

			getRolData();

			setIsLoading(false);
		})();
	}, []);

	const getRolData = async () => {
		setIsLoading(true);
		const result = await getRolByIdAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setSelectedRol(result.data.result);
		setIsLoading(false);
		setRolName(result.data.result.rolName);
		setNivelJerarquico(result.data.result.idRolSuperior);
		setIsFullAccess(result.data.result.isFullAccess);

		result.data.result.permissions.map((item) => {
			switch (item.description) {
				//Clients
				case 'CLIENTS VER':
					setClientVer(item.isEnable);
					break;
				case 'CLIENTS CREATE':
					setClientCreate(item.isEnable);
					break;
				case 'CLIENTS UPDATE':
					setClientUpdate(item.isEnable);
					break;
				case 'CLIENTS DELETE':
					setClientDelete(item.isEnable);
					break;

				//Credits
				case 'CREDITS VER':
					setCreditVer(item.isEnable);
					break;
				case 'CREDITS CREATE':
					setCreditCreate(item.isEnable);
					break;
				case 'CREDITS UPDATE':
					setCreditUpdate(item.isEnable);
					break;
				case 'CREDITS REGEN':
					setCreditRegen(item.isEnable);
					break;
				case 'CREDITS DELETE':
					setCreditDelete(item.isEnable);
					break;
				case 'CREDIT LIQUIDATE':
					setCreditLiquidate(item.isEnable);
					break;
				case 'PAYMENT UPDATE':
					setPaymentUpdate(item.isEnable);
					break;
				case 'PRINT PAGARE':
					setPrintPagare(item.isEnable);
					break;

				//Admon
				case 'CASHMOV VER':
					setCashMovVer(item.isEnable);
					break;
				case 'CASHMOV CREATE':
					setCashMovCreate(item.isEnable);
					break;
				case 'CASHMOV DELETE':
					setCashMovDelete(item.isEnable);
					break;

				case 'ADMON VER':
					setAdmonVer(item.isEnable);
					break;
				case 'ADMON CREATE':
					setAdmonCreate(item.isEnable);
					break;
				case 'CAJA VER':
					setCajaVer(item.isEnable);
					break;
				case 'CAJA CREATE':
					setCajaCreate(item.isEnable);
					break;

				//Reports
				case 'RCLIENTS':
					setRClients(item.isEnable);
					break;
				case 'RCREDXGEST':
					setRCredCgest(item.isEnable);
					break;
				case 'RCOBDIARIO':
					setRCobDiario(item.isEnable);
					break;
				case 'RECUPERAC':
					setRecuperac(item.isEnable);
					break;
				case 'RDESEMBOLSO':
					setRDesembolso(item.isEnable);
					break;
				case 'RSALCART':
					setRSalCart(item.isEnable);
					break;
				case 'RCREDVENC':
					setRCredVenc(item.isEnable);
					break;
				case 'RVENCPROY':
					setRVencProy(item.isEnable);
					break;
				case 'RCREDCANC':
					setRCredCanc(item.isEnable);
					break;
				case 'RACCOUNTSTS':
					setRAccountSts(item.isEnable);
					break;
				case 'RBOXCLOSE':
					setRBoxClose(item.isEnable);
					break;
				case 'RRESULTSTS':
					setRResultSts(item.isEnable);
					break;
				case 'KONAMI VER':
					setRConami(item.isEnable);
					break;

				//Security
				case 'SECURITY VER':
					setSecurityVer(item.isEnable);
					break;
				case 'SECURITY CREATE':
					setSecurityCreate(item.isEnable);
					break;
				case 'SECURITY UPDATE':
					setSecurityUpdate(item.isEnable);
					break;
				case 'SECURITY DELETE':
					setSecurityDelete(item.isEnable);
					break;
				case 'VER HORARIOS':
					setVerSchedule(item.isEnable);
					break;
				case 'EDITAR HORARIOS':
					setEditSchedule(item.isEnable);
					break;

				//Settings
				case 'CONFIG VER':
					setConfVer(item.isEnable);
					break;
				case 'CONFIG CREATE':
					setConfCreate(item.isEnable);
					break;
				case 'CONFIG UPDATE':
					setConfUpdate(item.isEnable);
					break;
				case 'CONFIG DELETE':
					setConfDelete(item.isEnable);
					break;
			}
		});
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						alignItems="center"
						justifyContent={isAccess(access, 'SECURITY UPDATE') ? 'space-between' : ''}
					>
						<Button
							onClick={() => {
								navigate(`${ruta}/rols`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Typography color={'#00a152'} variant="h4" fontWeight={'bold'}>
							Editar Rol
						</Typography>

						{isAccess(access, 'SECURITY UPDATE') ? (
							<Button
								onClick={() => {
									setIsEdit(!isEdit);
								}}
								style={{
									color: '#ffc400',
									borderColor: '#ffc400'
								}}
								variant="outlined"
							>
								<FontAwesomeIcon
									style={{ marginRight: 10, fontSize: 20 }}
									icon={faPenToSquare}
								/>
								Editar
							</Button>
						) : (
							<></>
						)}
					</Stack>

					<hr />

					<Typography variant="h6" color={'#1c54b2'} style={{ textAlign: 'left' }}>
						Configuraciones del Rol
					</Typography>

					<Paper
						style={{
							padding: 20,
							textAlign: 'center'
						}}
					>
						<Stack
							spacing={2}
							direction={{ xs: 'column', sm: 'row' }}
							justifyContent="space-between"
						>
							<TextField
								fullWidth
								style={{ maxWidth: 600 }}
								onChange={(e) => setRolName(e.target.value.toUpperCase())}
								label={'Nombre rol'}
								value={rolName}
								disabled={!isEdit}
							/>

							<FormControl
								disabled={!isEdit}
								fullWidth
								required
								style={{ maxWidth: 600 }}
							>
								<InputLabel id="demo-simple-select-standard-label">
									Seleccione un rol superior
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={nivelJerarquico}
									onChange={(e) => setNivelJerarquico(e.target.value)}
									label="Seleccione un rol superior"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione un rol superior</em>
									</MenuItem>
									{rolesList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.rolName}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Stack>

						<Typography
							variant="h6"
							fontWeight={'bold'}
							style={{
								color: '#2979ff'
							}}
						>
							Acceso Total al Sistema
						</Typography>

						<Switch
							disabled={!isEdit}
							style={{
								color: isFullAccess ? '#4caf50' : '#f50057'
							}}
							checked={isFullAccess}
							onChange={handleChangeFullAccess}
						/>

						<Stack spacing={2}>
							{/* Clientes */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Clientes
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={clientVer}
												onChange={() => setClientVer(!clientVer)}
											/>
										}
										label="Ver Clientes"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={clientCreate}
												onChange={() => setClientCreate(!clientCreate)}
											/>
										}
										label="Crear Clientes"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												disabled={!isEdit}
												checked={clientUpdate}
												onChange={() => setClientUpdate(!clientUpdate)}
											/>
										}
										label="Actualizar Cliente"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												disabled={!isEdit}
												checked={clientDelete}
												onChange={() => setClientDelete(!clientDelete)}
											/>
										}
										label="Eliminar Cliente"
									/>
								</Stack>
							</Paper>

							{/* Credits */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Créditos
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
									marginTop={1}
									spacing={1}
								>
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Permisos de Crédito
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={creditVer}
														onChange={() => setCreditVer(!creditVer)}
													/>
												}
												label="Ver Creditos"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={creditCreate}
														onChange={() =>
															setCreditCreate(!creditCreate)
														}
													/>
												}
												label="Crear Creditos"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={creditUpdate}
														onChange={() =>
															setCreditUpdate(!creditUpdate)
														}
													/>
												}
												label="Actualizar Credito"
											/>
										</Stack>
									</Paper>

									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Permisos Especiales de Crédito
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'column', md: 'row' }}
											display="flex"
											justifyContent="space-between"
										>
											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={creditRegen}
														onChange={() =>
															setCreditRegen(!creditRegen)
														}
													/>
												}
												label="Regenerar Credito"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={creditLiquidate}
														onChange={() =>
															setCreditLiquidate(!creditLiquidate)
														}
													/>
												}
												label="Liquidar Credito"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={creditDelete}
														onChange={() =>
															setCreditDelete(!creditDelete)
														}
													/>
												}
												label="Eliminar Credito"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={paymentUpdate}
														onChange={() =>
															setPaymentUpdate(!paymentUpdate)
														}
													/>
												}
												label="Cambiar F. Pago"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={printPagare}
														onChange={() =>
															setPrintPagare(!printPagare)
														}
													/>
												}
												label="Imprimir Pagaré"
											/>
										</Stack>
									</Paper>
								</Stack>
							</Paper>

							{/* Admon */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Administración
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
									marginTop={1}
									spacing={2}
								>
									{/* Permisos Caja general */}
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Caja
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={cajaVer}
														onChange={() => setCajaVer(!cajaVer)}
													/>
												}
												label="Ver Caja"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={cajaCreate}
														onChange={() => setCajaCreate(!cajaCreate)}
													/>
												}
												label="Crear Movimiento"
											/>
										</Stack>
									</Paper>

									{/* Permisos Caja Chica */}
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Caja Chica
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={cashMovVer}
														onChange={() => setCashMovVer(!cashMovVer)}
													/>
												}
												label="Ver Caja"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={cashMovCreate}
														onChange={() =>
															setCashMovCreate(!cashMovCreate)
														}
													/>
												}
												label="Crear Movimiento"
											/>
										</Stack>
									</Paper>

									{/* Permisos Contabilidad */}
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Gastos
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={admonVer}
														onChange={() => setAdmonVer(!admonVer)}
													/>
												}
												label="Ver Gastos"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={admonCreate}
														onChange={() =>
															setAdmonCreate(!admonCreate)
														}
													/>
												}
												label="Crear Gastos"
											/>
										</Stack>
									</Paper>
								</Stack>
							</Paper>

							{/* Reports */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Reportes
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rClients}
												onChange={() => setRClients(!rClients)}
											/>
										}
										label="Clientes por Gestor"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rCredXGest}
												onChange={() => setRCredCgest(!rCredXGest)}
											/>
										}
										label="Creditos por Gestor"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rCobDiario}
												onChange={() => setRCobDiario(!rCobDiario)}
											/>
										}
										label="Cobro Diario"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={recuperac}
												onChange={() => setRecuperac(!recuperac)}
											/>
										}
										label="Recuperación"
									/>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rDesembolso}
												onChange={() => setRDesembolso(!rDesembolso)}
											/>
										}
										label="Desembolso"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rSalCart}
												onChange={() => setRSalCart(!rSalCart)}
											/>
										}
										label="Saldos Cartera"
									/>
								</Stack>

								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rCredVenc}
												onChange={() => setRCredVenc(!rCredVenc)}
											/>
										}
										label="Créditos Vencidos"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rVencProy}
												onChange={() => setRVencProy(!rVencProy)}
											/>
										}
										label="Vencimientos Proyectados"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rCredCanc}
												onChange={() => setRCredCanc(!rCredCanc)}
											/>
										}
										label="Créditos Cancelados"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rAccountSts}
												onChange={() => setRAccountSts(!rAccountSts)}
											/>
										}
										label="Estado de Cuenta"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rBoxClose}
												onChange={() => setRBoxClose(!rBoxClose)}
											/>
										}
										label="Cierre de Caja"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rResultSts}
												onChange={() => setRResultSts(!rResultSts)}
											/>
										}
										label="Estado de Resultado"
									/>
								</Stack>

								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={rConami}
												onChange={() => setRConami(!rConami)}
											/>
										}
										label="Reporte Conami"
									/>
								</Stack>
							</Paper>

							{/* Security */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Seguridad
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
									marginTop={1}
									spacing={1}
								>
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Usuarios y Roles
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-around"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={securityVer}
														onChange={() =>
															setSecurityVer(!securityVer)
														}
													/>
												}
												label="Ver"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={securityCreate}
														onChange={() =>
															setSecurityCreate(!securityCreate)
														}
													/>
												}
												label="Crear"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={securityUpdate}
														onChange={() =>
															setSecurityUpdate(!securityUpdate)
														}
													/>
												}
												label="Actualizar"
											/>

											<FormControlLabel
												labelPlacement="top"
												style={{
													textAlign: 'center'
												}}
												control={
													<Checkbox
														disabled={!isEdit}
														checked={securityDelete}
														onChange={() =>
															setSecurityDelete(!securityDelete)
														}
													/>
												}
												label="Eliminar"
											/>
										</Stack>
									</Paper>

									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											style={{
												fontSize: 17,
												color: '#2196f3',
												fontWeight: 'bold',
												textAlign: 'center'
											}}
										>
											Accesos al Servidor
										</Typography>
										<Divider />
										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											display="flex"
											justifyContent="space-between"
										>
											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={verSchedule}
														onChange={() =>
															setVerSchedule(!verSchedule)
														}
													/>
												}
												label="Ver Horarios"
											/>

											<FormControlLabel
												labelPlacement="top"
												control={
													<Checkbox
														disabled={!isEdit}
														checked={editSchedule}
														onChange={() =>
															setEditSchedule(!editSchedule)
														}
													/>
												}
												label="Editar Horarios"
											/>
										</Stack>
									</Paper>
								</Stack>
							</Paper>

							{/* Configurations */}
							<Paper
								style={{
									padding: 10
								}}
							>
								<Typography
									style={{
										fontSize: 17,
										color: '#2196f3',
										fontWeight: 'bold',
										textAlign: 'center'
									}}
								>
									Modulo Configuracion
								</Typography>
								<Divider />
								<Stack
									direction={{ xs: 'column', sm: 'column', md: 'row' }}
									display="flex"
									justifyContent="space-around"
								>
									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={confVer}
												onChange={() => setConfVer(!confVer)}
											/>
										}
										label="Ver Configuraciones"
									/>

									<FormControlLabel
										labelPlacement="top"
										control={
											<Checkbox
												disabled={!isEdit}
												checked={confCreate}
												onChange={() => setConfCreate(!confCreate)}
											/>
										}
										label="Crear Configuraciones"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												disabled={!isEdit}
												checked={confUpdate}
												onChange={() => setConfUpdate(!confUpdate)}
											/>
										}
										label="Actualizar Configuraciones"
									/>

									<FormControlLabel
										labelPlacement="top"
										style={{
											textAlign: 'center'
										}}
										control={
											<Checkbox
												disabled={!isEdit}
												checked={confDelete}
												onChange={() => setConfDelete(!confDelete)}
											/>
										}
										label="Eliminar Configuraciones"
									/>
								</Stack>
							</Paper>

							<Button
								disabled={!isEdit}
								fullWidth
								variant="outlined"
								style={{ color: '#2979ff', borderColor: '#2979ff' }}
								startIcon={<FontAwesomeIcon icon={faSave} />}
								onClick={() => saveRol()}
							>
								Actualizar Rol
							</Button>
						</Stack>
					</Paper>
				</Paper>
			</Container>
		</div>
	);
};

export default EditRol;
