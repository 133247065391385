import React, { useContext } from "react";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Stack,
} from "@mui/material";
import moment from "moment";

import { DataContext } from "../../../context/DataContext";

export const PrintPayment = React.forwardRef((props, ref) => {
  const { title, slogan, icon } = useContext(DataContext);
  const { id, fechaEntrega } = props.credit;

  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Stack direction="row" justifyContent="space-between">
          <img src={icon} style={{ width: 70, height: 70 }} />
          <Stack>
            <Typography variant="h5" color="#212121" textAlign={"center"}>
              {title}
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              {slogan}
            </Typography>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              Plan de Pagos
            </Typography>
          </Stack>
          <img src={icon} style={{ width: 70, height: 70 }} />
        </Stack>

        <Stack direction="row" justifyContent={"center"} spacing={1}>
          <Typography color="#212121" fontWeight={"bold"}>
            Cliente:
          </Typography>
          <Typography color="#212121">{props.clientName}</Typography>
        </Stack>

        <Stack direction="row" justifyContent={"center"} spacing={1}>
          <Stack direction="row" justifyContent={"center"} spacing={1}>
            <Typography color="#212121" fontWeight={"bold"}>
              Credito #:
            </Typography>
            <Typography color="#212121">{id}</Typography>
          </Stack>

          <Stack direction="row" justifyContent={"center"} spacing={1}>
            <Typography color="#212121" fontWeight={"bold"}>
              Fecha Desembolso:
            </Typography>
            <Typography color="#212121">
              {moment(fechaEntrega).format("L")}
            </Typography>
          </Stack>
        </Stack>

        <Container maxWidth="lg">
          <TableContainer>
            <Table
              size="small"
              aria-label="simple table"
              sx={{ border: 1, borderColor: "#212121" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Fecha Pago
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Saldo Anterior
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Cuota Principal
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Días
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Cuota I. Corriente
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Cuota Pagar
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#212121",
                    }}
                  >
                    Nuevo Saldo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.paymentList.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {row.numeroCuota}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {moment(row.fechaPago).format("L")}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(row.saldoAnterior)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(row.montoPrincipal)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {row.periodicidad}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(row.montoInteresCorriente)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(row.cuotaPrincipal)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: 11, color: "#212121" }}
                        component="th"
                        scope="row"
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(row.nuevoSaldo)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction={"row-reverse"} spacing={1} marginTop={10}>
            <Typography
              style={{ textDecoration: "overline" }}
              textAlign={"right"}
            >
              {`${props.clientName}`}
            </Typography>
            <Typography textAlign={"right"}>FIRMA DEUDOR:</Typography>
          </Stack>
        </Container>
      </>
    </div>
  );
});

export default PrintPayment;
