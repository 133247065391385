import React, { useState, useLayoutEffect, useContext, useRef, useEffect } from 'react';
import { Container, Stack, AppBar, Toolbar, Typography, Dialog } from '@mui/material';
import { isEmpty } from 'lodash';
// import mapboxgl, { Map, Marker, Popup } from 'mapbox-gl';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';
import { getToken, getUser } from '../../../api/Account';
import NoData from '../../../components/NoData';
import moment from 'moment';
import { simpleMessage } from '../../../helpers/Helpers';

import { getGestoresBySucursalAccessAsync } from '../../../api/Users';
import { getUserTrackingAsync } from '../../../api/Report';

const ReporteUserTracking = () => {
	const [data, setData] = useState([]);
	const [gestorName, setGestorName] = useState('');

	const [center, setCenter] = useState([]);
	const [recorrido, setRecorrido] = useState([]);

	const { selectedGestor, selectedFecha } = useParams();
	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const mark = require('../../../components/media/marker.png');

	const token = getToken();
	const user = getUser();

	const getData = async () => {
		const data = {
			fecha: selectedFecha.toString(),
			userId: selectedGestor
		};

		setIsLoading(true);
		const result = await getUserTrackingAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);

		setData(result.data.result);
		return result.data.result;
	};

	useEffect(() => {
		(async () => {
			await getGestores();
			const data = await getData();

			let coords = [];
			let pints = [];

			data.map((item) => {
				const coord = [item.latitude, item.longitude, item.fecha];
				const point = [item.latitude, item.longitude];

				coords.push(coord);
				pints.push(point);
			});
			// setRecorrido(coords);
			setRecorrido(pints);
			setCenter([coords[0][0], coords[0][1]]);
		})();
	}, []);

	const getGestores = async () => {
		setIsLoading(true);
		const result = await getGestoresBySucursalAccessAsync(token);

		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		let gestores = result.data.result;
		let name = gestores.filter((item) => item.id.toString() === selectedGestor);

		setGestorName(name[0].fullName);
	};

	const customIcon = new Icon({
		iconUrl: mark,
		iconSize: [38, 38]
	});

	return (
		<Dialog fullScreen open={true}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					<Typography
						sx={{ ml: 2, flex: 1, textAlign: 'center' }}
						variant="h5"
						component="div"
					>
						{`${title} `}
					</Typography>
					<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
				</Toolbar>
			</AppBar>
			<Stack display="flex" justifyContent="center">
				<Typography
					sx={{
						color: '#2196f3',
						textAlign: 'center',
						fontWeight: 'bold'
					}}
				>
					{slogan}
				</Typography>

				<Typography
					sx={{
						color: '#2196f3',
						textAlign: 'center',
						fontWeight: 'bold'
					}}
					variant="h6"
					component="div"
				>
					Reporte de Restreo de usuarios
				</Typography>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Parámetros:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{`Gestor Seleccionado: ${gestorName}`}
							</Typography>

							<Stack direction={'row'} spacing={1}>
								<Typography fontSize={11} textAlign="left">
									{/* {`Fecha Seleccionada: ${moment(selectedFecha).format(
										'L'
									)} `} */}
								</Typography>
							</Stack>
						</Stack>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'left'
								}}
								fontSize={11}
							>
								Usuario:
							</Typography>
							<Typography fontSize={11} textAlign="left">
								{user}
							</Typography>

							<Typography fontSize={11} textAlign="left">
								{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
							</Typography>
						</Stack>
					</Stack>
				</Container>
			</Stack>
			<Container maxWidth={false} sx={{ textAlign: 'center' }}>
				<hr />

				{isEmpty(data) ? (
					<NoData />
				) : (
					<MapContainer
						center={center}
						zoom={16}
						style={{
							height: '100vh'
						}}
					>
						<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						/>
						<Polyline positions={recorrido} color="blue" />
						{data.map((item, index) => {
							if (index === 0) {
								return (
									<Marker
										key={item.id}
										position={[item.latitude, item.longitude]}
										icon={customIcon}
									>
										<Popup>
											<Typography
												fontWeight={'bold'}
												textAlign={'center'}
												component={'h6'}
											>
												{moment(item.fecha).format('HH:mm A')}
											</Typography>
											<p>Punto Inicial</p>
										</Popup>
									</Marker>
								);
							} else {
								if (item.tiempo / 60 >= 2) {
									return (
										<Marker
											key={item.id}
											position={[item.latitude, item.longitude]}
											icon={customIcon}
										>
											<Popup>
												<Stack>
													<Typography
														fontWeight={'bold'}
														textAlign={'center'}
														component={'h6'}
													>
														{moment(item.fecha).format('HH:mm A')}
													</Typography>
													<Typography
														textAlign={'center'}
														component="span"
													>{`Tiempo: ${Math.round(
														item.tiempo / 60
													)} minutos`}</Typography>
													<Typography
														textAlign={'center'}
														component={'span'}
													>{`Precicion: ${item.position.coords.accuracy.toFixed(
														2
													)} metros`}</Typography>
												</Stack>
											</Popup>
										</Marker>
									);
								}
							}
						})}
					</MapContainer>
				)}
			</Container>
		</Dialog>
	);
};

export default ReporteUserTracking;
