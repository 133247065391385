import React, { useState, useEffect, useContext } from 'react';
import { Container, Paper, Typography, Stack, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faCalendarCheck,
	faCalendarXmark,
	faCashRegister,
	faChartPie,
	faCoins,
	faDownload,
	faHandHoldingDollar,
	faMoneyBillTrendUp,
	faRoute,
	faScaleBalanced,
	faUserCheck,
	faUsers,
	faUsersViewfinder,
	faWallet
} from '@fortawesome/free-solid-svg-icons';
import CallerSelector from '../../components/CallerSelector';
import SelectorClientsGestor from './selectores/selectoresCartera/SelectorClientsGestor';
import SelectorCreditsByGestor from './selectores/selectoresCartera/SelectorCreditsByGestor';
import SelectorCobroDiario from './selectores/selectoresCartera/SelectorCobroDiario';
import SelectorRecuperacion from './selectores/selectoresCartera/SelectorRecuperacion';
import SelectorDesembolsos from './selectores/selectoresCartera/SelectorDesembolsos';
import SelectorSaldosCartera from './selectores/selectoresCartera/SelectorSaldosCartera';
import SelectorCreditosVencidos from './selectores/selectoresCartera/SelectorCreditosVencidos';
import SelectorAccountStatus from './selectores/selectoresCartera/SelectorAccountStatus';
import SelectorCierreDiario from './selectores/selectoresCartera/SelectorCierreDiario';
import SelectorEstadoResultado from './selectores/selectoresCartera/SelectorEstadoResultado';
import SelectorCreditsCancelled from './selectores/selectoresCartera/SelectorCreditsCancelled';
import SelectorVencimientosProyectados from './selectores/selectoresCartera/SelectorVencimientosProyectados';
import { isAccess } from '../../helpers/Helpers';
import { DataContext } from '../../context/DataContext';
import SelectorUsertracking from './SelectorUsertracking';
import CallerDownload from '../../components/CallerDownload';
import SelectorSinRiesgo from './selectores/selectoresCartera/SelectorSinRiesgo';

const Reports = () => {
	const { access } = useContext(DataContext);
	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={'row'}>
						<Typography
							color={'#00a152'}
							textAlign="left"
							variant="h4"
							fontWeight={'bold'}
						>
							<FontAwesomeIcon
								icon={faWallet}
								style={{ marginRight: 20 }}
								className="fa-beat"
							/>
						</Typography>
						<Typography
							color={'#00a152'}
							textAlign="left"
							variant="h4"
							fontWeight={'bold'}
							className="d-none d-sm-block"
						>
							Reportes de Cartera
						</Typography>
					</Stack>

					<hr />

					<Grid container spacing={2} justifyContent="center">
						{isAccess(access, 'RCLIENTS') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faUsers}
									text="Reporte de Clientes"
									modalTitle="Reporte de Clientes"
								>
									<SelectorClientsGestor />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RCREDXGEST') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faHandHoldingDollar}
									text="Créditos por Gestor"
									modalTitle="Créditos por Gestor"
								>
									<SelectorCreditsByGestor />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RCOBDIARIO') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faCalendarCheck}
									text="Cobro Diario"
									modalTitle="Cobro Diario"
								>
									<SelectorCobroDiario />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RECUPERAC') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faUserCheck}
									text="Recuperación"
									modalTitle="Reporte de Recuperacion"
								>
									<SelectorRecuperacion isByClient={false} />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RECUPERAC') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faUsersViewfinder}
									text="Recuperación Por CLiente"
									modalTitle="Reporte de Recuperacion por Cliente"
								>
									<SelectorRecuperacion isByClient={true} />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RDESEMBOLSO') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faWallet}
									text="Desembolsos"
									modalTitle="Reporte de Desembolsos"
								>
									<SelectorDesembolsos />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RSALCART') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faCoins}
									text="Saldos Cartera"
									modalTitle="Reporte Saldos Cartera"
								>
									<SelectorSaldosCartera />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RCREDVENC') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faCalendarXmark}
									text="Créditos Vencidos"
									modalTitle="Reporte Créditos Vencidos"
								>
									<SelectorCreditosVencidos />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RVENCPROY') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faCalendar}
									text="Vencimientos Proyectados"
									modalTitle="Reporte Vencimientos proyectados"
								>
									<SelectorVencimientosProyectados />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RCREDCANC') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faMoneyBillTrendUp}
									text="Créditos Cancelados"
									modalTitle="Reporte Créditos Cancelados"
								>
									<SelectorCreditsCancelled />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}
						{isAccess(access, 'RACCOUNTSTS') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faScaleBalanced}
									text="Estado de Cuenta"
									modalTitle="Reporte de Estado de Cuenta"
								>
									<SelectorAccountStatus />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RBOXCLOSE') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faCashRegister}
									text="Cierre de Caja"
									modalTitle="Reporte de Cierre de Caja"
								>
									<SelectorCierreDiario />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'RRESULTSTS') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faChartPie}
									text="Estado de Resultado"
									modalTitle="Reporte Estado de Resultado"
								>
									<SelectorEstadoResultado />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'REP UTRACK') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faRoute}
									text="Rastreo de Usuarios"
									modalTitle="Reporte Ubicaciones por Usuario"
								>
									<SelectorUsertracking />
								</CallerSelector>
							</Grid>
						) : (
							<></>
						)}

						{isAccess(access, 'KONAMI VER') ? (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<CallerSelector
									icon={faDownload}
									text="Reporte Sin Riesgo"
									modalTitle="Reporte Sin Riesgo"
								>
									<SelectorSinRiesgo/>
								</CallerSelector>
								{/* <CallerDownload
									icon={faDownload}
									text="Reporte Sin Riesgo"
									modalTitle="Reporte Sin Riesgo"
								/> */}
							</Grid>
						) : (
							<></>
						)}
					</Grid>
				</Paper>
			</Container>
		</div>
	);
};

export default Reports;
