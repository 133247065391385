import React, { useState, useContext } from "react";
import {
  TextField,
  Divider,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";

import { addConceptoGastoAsync } from "../../../api/Contabilidad";

const AddConceptoGasto = ({ setShowModal, localReload, setLocalReload }) => {
  const [description, setDescription] = useState("");
  const [tipoGasto, setTipogasto] = useState(1);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const saveChangesAsync = async () => {
    if (description.length === 0) {
      simpleMessage("Ingrese una descripción...", "error");
      return;
    }
    const data = {
      tipoGastoId: tipoGasto,
      description,
    };

    setIsLoading(true);
    const result = await addConceptoGastoAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Concepto Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Divider />
      <Container style={{ width: 450 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-standard-label">
            Tipo Gasto
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => setTipogasto(e.target.value)}
            value={tipoGasto}
            label="Tipo Gasto"
          >
            <MenuItem value={1}>Gastos Administrativos</MenuItem>
            <MenuItem value={2}>Gastos Operativos</MenuItem>
            <MenuItem value={3}>Otros Gastos</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => setDescription(e.target.value.toUpperCase())}
          label={"Descripcion"}
          value={description}
        />

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            marginTop: 30,
            borderRadius: 20,
            color: "#00a152",
            borderColor: "#00a152",
          }}
          size="large"
        >
          Agregar Concepto
        </Button>
      </Container>
    </div>
  );
};

export default AddConceptoGasto;
