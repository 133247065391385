import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}ubications`;
} else {
  Api = `${REACT_APP_URL}ubications`;
}

export const getDepartmentListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetDepartments";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getOnlyDepartmentListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetOnlyDepartments";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getMunicipalitiesByDeptopAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetMunicipalitiesByDepartment/";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url + id).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCommunitiesByMunAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetCommsByMunicipality/";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url + id).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getDepartmentByIdAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetDepartment/";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url + id).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const updateMunicipalityAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/UpdateMunicipality/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getMunicipalityByIdAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetMunicipality/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service + id).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const addCommunityAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(Api, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const deleteCommunityAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/DeleteCommunity/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service + data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const updateCommunityAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/UpdateCommunity/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
