import React, { useState, useContext } from "react";
import {
  TextField,
  Divider,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { simpleMessage } from "../../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../../api/Account";
import { addCommunityAsync } from "../../../../api/Locations";
import { Save } from "@mui/icons-material";

const CommunityAdd = ({ setShowModal, idMunicipality }) => {
  const token = getToken();

  const { reload, setReload, setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);
  const [name, setName] = useState("");

  const saveChangesAsync = async () => {
    const data = {
      idMunicipality,
      name,
    };

    if (name === "") {
      simpleMessage("Ingrese un nombre...", "error");
      return;
    }
    setIsLoading(true);
    const result = await addCommunityAsync(token, data);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Comunidad Agregada...!", "success");
    setReload(!reload);
    setShowModal(false);
  };

  return (
    <div>
      <Container style={{ width: 450 }}>
        <Divider />
        <TextField
          fullWidth
          required
          style={{ marginBottom: 20, marginTop: 20 }}
          onChange={(e) => setName(e.target.value.toUpperCase())}
          label={"Nombre Comunidad"}
          value={name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => saveChangesAsync()}>
                  <Save style={{ color: "#2979ff" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};

export default CommunityAdd;
