import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  IconButton,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCirclePlus,
  faTrashAlt,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

import Swal from "sweetalert2";
import { DataContext } from "../../../context/DataContext";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";

import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import { deleteRolByIdAsync, getRolListAsync } from "../../../api/Rols";

const RolList = () => {
  const [rolList, setRolList] = useState([]);

  const [localReload, setLocalReload] = useState(false);
  const { setIsLoading, setIsLogged, setIsUnautorized, access } =
    useContext(DataContext);
  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const withSearch = rolList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (
      val.id.toString().includes(searchTerm) ||
      val.rolName.toString().includes(searchTerm)
    ) {
      return val;
    }
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const result = await getRolListAsync(token);

      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setRolList(result.data.result);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  const deleteUserRol = async (item) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "No podras revertir esto...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Eliminar!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await deleteRolByIdAsync(token, item.id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          if (result.error === "eX01") {
            setIsLoading(false);
            deleteUserData();
            deleteToken();
            setIsLogged(false);
            return;
          }
          setIsLoading(false);
          setLocalReload(!localReload);
          Swal.fire("Eliminado!", "Rol Eliminado.", "success");
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Lista de Roles
            </Typography>

            {isAccess(access, "SECURITY CREATE") ? (
              <Button
                onClick={() => {
                  navigate(`${ruta}/addrol`);
                }}
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                variant="outlined"
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar Rol
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar Rol"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#1769aa" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="text-primary fw-bold">
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Nombre Rol
                      </TableCell>

                      {isAccess(access, "SECURITY UPDATE") ||
                      isAccess(access, "SECURITY DELETE") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Acciones
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="left" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="left">{row.rolName}</TableCell>

                          {isAccess(access, "SECURITY UPDATE") ||
                          isAccess(access, "SECURITY DELETE") ? (
                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                spacing={1}
                                justifyContent="center"
                              >
                                {isAccess(access, "SECURITY UPDATE") ? (
                                  <IconButton
                                    style={{
                                      color: "#ffc400",
                                    }}
                                    onClick={() => {
                                      navigate(`${ruta}/rol/${row.id}`);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faExternalLink} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}

                                {isAccess(access, "SECURITY DELETE") ? (
                                  <IconButton
                                    style={{
                                      color: "#f50057",
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() => deleteUserRol(row)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default RolList;
