import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Typography,
  Container,
  Stack,
  Button,
  Paper,
  InputAdornment,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { isAccess, simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import {
  addQuoteAsync,
  addQuoteNominalAsync,
  getQuoteSugestAsync,
  getSaldoAnteriorAsync,
  liquidateCreditAsync,
} from "../../../../api/Credits";

const AddAbono = ({
  setShowModal,
  id,
  localReload,
  setLocalReload,
  isNominal,
}) => {
  const [montoAbono, setMontoAbono] = useState("");
  const [concepto, setConcepto] = useState("");
  const [active, setActive] = useState(1);
  const [quoteSugest, setquoteSugest] = useState(0);
  const [totalAbonado, setTotalAbonado] = useState(0);
  const [nuevoSaldo, setNuevoSaldo] = useState(0);
  const [isLiquidate, setIsLiquidate] = useState(false);

  const [tipoAbono, setTipoAbono] = useState(1);

  const token = getToken();
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getQuoteSugestAsync(token, id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setquoteSugest(result.data.result);
      getSaldoAnterior();
    })();
  }, []);
  //Devuelve un entero positivo
  const funtionSetMontoCuota = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString())) {
      if (value > totalAbonado) {
        simpleMessage("No puede abonar mas de lo que debe", "error");
        setMontoAbono(totalAbonado);
        setNuevoSaldo(0);
        return;
      }
      setMontoAbono(value);
      setNuevoSaldo(
        parseFloat(totalAbonado) - parseFloat(value === "" ? 0 : value)
      );
      return;
    }
  };

  const addCuota = async () => {
    if (montoAbono.length === 0) {
      simpleMessage("Debe ingresar un monto a abonar", "error");
      return;
    }

    if (nuevoSaldo < 0) {
      simpleMessage(
        "No puede ingresar una cuota mayor al saldo del credito",
        "error"
      );
      return;
    }
    setIsLoading(true);
    const data = {
      creditId: id,
      totalRecibido: montoAbono,
      concepto: tipoAbono === "1" ? "ABONO DEL DIA" : "ABONO",
      moneda: active,
    };

    setIsLoading(true);
    const result = isNominal
      ? await addQuoteNominalAsync(token, data)
      : isLiquidate
      ? await liquidateCreditAsync(token, data)
      : await addQuoteAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    simpleMessage("Cuota Aplicada...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  const getSaldoAnterior = async () => {
    const result = await getSaldoAnteriorAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setTotalAbonado(result.data.result);
  };

  const onChangeTipoAbono = (tipo) => {
    setTipoAbono(tipo);
    setConcepto(tipo === 1 ? "ABONO DEL DIA" : "ABONO");
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Stack direction="column" spacing={1}>
          <Paper
            style={{
              padding: 20,
            }}
          >
            <Stack direction="column" spacing={2} justifyContent={"center"}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack spacing={1} direction="row" justifyContent={"center"}>
                  <Typography
                    fontWeight={"bold"}
                    style={{
                      color: "#2979ff",
                    }}
                  >
                    CREDITO # :
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {id}
                  </Typography>
                </Stack>

                <Stack spacing={1} direction="row" justifyContent={"center"}>
                  <Typography
                    fontWeight={"bold"}
                    style={{
                      color: "#00a152",
                    }}
                  >
                    Ponerse Al Día :
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {`${new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(quoteSugest)}`}
                  </Typography>
                </Stack>
              </Stack>

              <Divider />

              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Moneda
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => setActive(e.target.value)}
                  value={active}
                  label="Municipio"
                >
                  <MenuItem value={1}>CORDOBA C$</MenuItem>
                  <MenuItem value={2}>DOLAR $</MenuItem>
                </Select>
              </FormControl>

              <TextField
                required
                fullWidth
                onChange={(e) => funtionSetMontoCuota(e.target.value)}
                value={montoAbono}
                label={"Monto a abonar"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {active === 1 ? "C$" : "$"}
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl fullWidth style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo de abono
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => onChangeTipoAbono(e.target.value)}
                  value={tipoAbono}
                  label="Tipo de Abono"
                >
                  <MenuItem value={1}>ABONO DEL DIA</MenuItem>
                  <MenuItem value={2}>ABONO</MenuItem>
                </Select>
              </FormControl>

              {isAccess(access, "CREDIT LIQUIDATE") ? (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  spacing={1}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    style={{ color: "#ffc400", fontWeight: "bold" }}
                    control={
                      <Checkbox
                        checked={isLiquidate}
                        onChange={() => setIsLiquidate(!isLiquidate)}
                        sx={{
                          color: "#ffc400",
                          "&.Mui-checked": {
                            color: "#ffc400",
                          },
                        }}
                      />
                    }
                    label="Abonar y Liquidar"
                  />
                </Stack>
              ) : (
                <></>
              )}

              {/* <TextField
                required
                fullWidth
                onChange={(e) => setConcepto(e.target.value.toUpperCase())}
                value={concepto}
                label={"Concepto de pago"}
              /> */}

              <Button
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
                }
                fullWidth
                onClick={() => addCuota()}
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
                size="large"
              >
                Agregar Cuota
              </Button>
            </Stack>
          </Paper>

          <Paper
            style={{
              padding: 10,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack spacing={1} justifyContent={"center"}>
                <Typography
                  fontWeight={"bold"}
                  textAlign="center"
                  style={{
                    color: "#00a152",
                  }}
                >
                  Saldo Anterior:
                </Typography>
                <Typography
                  textAlign="center"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {`${new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(totalAbonado)}`}
                </Typography>
              </Stack>

              <Stack spacing={1} justifyContent={"center"}>
                <Typography
                  textAlign="center"
                  fontWeight={"bold"}
                  style={{
                    color: "#f50057",
                  }}
                >
                  Nuevo Saldo:
                </Typography>
                <Typography
                  textAlign="center"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {`${new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(nuevoSaldo)}`}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </div>
  );
};

export default AddAbono;
