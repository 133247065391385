import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider
} from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getRecuperacionByClientAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import {
	getGestoresAsync,
	getGestoresBySucursalAccessAsync,
	getSucursalByUserAsync
} from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';

const RecuperacionByCLient = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');
	const [sucursalList, setSucursalList] = useState([]);

	const [gestorName, setGestorName] = useState('');
	const [gestorList, setGestorList] = useState([]);

	const compRef = useRef();
	const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			let sucursals = await getSucursals();

			if (selectedSucursal === 't') {
				setSucursalName('Todas');
			} else {
				let name = sucursals.filter((item) => item.id.toString() === selectedSucursal);
				setSucursalName(name[0].description);
			}

			const data = {
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString(),
				gestorId: selectedGestor === 't' ? 0 : selectedGestor,
				sucursalId: selectedSucursal === 't' ? 0 : selectedSucursal
			};

			setIsLoading(true);
			const result = await getRecuperacionByClientAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(
			selectedSucursal === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedSucursal)
		);

		let gestores = await getGestores(selectedSucursal);

		if (selectedGestor === 't') {
			setGestorName('Todos');
		} else {
			let name = gestores.filter((item) => item.id.toString() === selectedGestor);

			setGestorName(name[0].fullName);
		}
		return result.data.result;
	};

	const getGestores = async (sucursal) => {
		setIsLoading(true);
		const result =
			sucursal === 't'
				? await getGestoresBySucursalAccessAsync(token)
				: await getGestoresAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setGestorList(result.data.result);
		return result.data.result;
	};

	const pageStyle = `
      @media print {
        html, body {
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      @media all {
        .pagebreak {
          display: none;
        }
      }
      @media print {
        .pagebreak {
          page-break-before: always;
        }
      }  
      @page { size: landscape;  margin: 3mm; }
    `;

	let totalCuotasConsol = 0;
	let totalCobrosConsol = 0;
	let totalCapConsol = 0;
	let totalICConsol = 0;
	let totalCobradoConsol = 0;

	

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			const dataGestor = gestorList.filter((gestor) => gestor.id === item.gestor);
			let gestor = dataGestor[0];
			let obj = {
				id: item.id,
				cliente: item.fullName,
				montoEntregado: item.montoEntregado,
				cuota: item.cuotaPagar,
				fechaEntrega: moment(item.fechaEntrega).format('L'),
				fechaVencimiento: moment(item.fechaVencimiento).format('L'),
				AbonoCapital: item.aCapital,
				abonoInteres: item.aIntCorriente,
				totalRecibido: item.totalRecibido,
				cuotas: item.cuotas,
				gestor: isUndefined(gestor) ? item.gestor : gestor.fullName,
				sucursal: item.sucursal
			};
			exp.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'recuperacionPorCliente');
		XLSX.writeFile(bk, 'RecuperacionPoCliente.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Recuperación por Cliente
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>
				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							{sucursalList.map((suc) => {
								const sSucursal = data.filter(
									(c) => c.sucursal === suc.description
								);

								return (
									<Stack key={suc.description}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'left'
												}}
												fontSize={11}
											>
												Sucursal:
											</Typography>
											<Typography
												sx={{
													textAlign: 'left'
												}}
												fontSize={11}
											>
												{`${suc.description}`}
											</Typography>
										</Stack>

										{gestorList.map((gestor) => {
											const cGestor = sSucursal.filter(
												(c) => c.gestor === gestor.id
											);

											let totalCuotas = 0;
											let totalCap = 0;
											let totalIC = 0;
											let totalCobrado = 0;

											cGestor.map((c) => {
												totalCuotas += c.cuotas;
												totalCap += c.aCapital;
												totalIC += c.aIntCorriente;
												totalCobrado += c.totalRecibido;
											});

											totalCuotasConsol += totalCuotas;
											totalCobrosConsol += cGestor.length;
											totalCapConsol += totalCap;
											totalICConsol += totalIC;
											totalCobradoConsol += totalCobrado;

											return isEmpty(cGestor) ? (
												<></>
											) : (
												<Stack key={gestor.id}>
													<Stack direction="row" spacing={1}>
														<Typography
															sx={{
																color: '#2196f3',
																textAlign: 'left'
															}}
															fontSize={11}
														>
															Gestor:
														</Typography>
														<Typography
															sx={{
																textAlign: 'left'
															}}
															fontSize={11}
														>
															{gestor.fullName}
														</Typography>
													</Stack>

													<Divider />

													<TableContainer>
														<Table
															aria-label="simple table"
															size="small"
														>
															<TableHead>
																<TableRow>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		#
																	</TableCell>
																	<TableCell
																		align="left"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		Cliente
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		Desembolso
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		Cuota
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		F.Entrega
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		F.Vencimiento
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		A.Capital
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		Abono I.C.
																	</TableCell>

																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		Total Recibido
																	</TableCell>
																	<TableCell
																		align="center"
																		className="text-primary fw-bold"
																		style={{ fontSize: 11 }}
																	>
																		Cuotas
																	</TableCell>
																</TableRow>
															</TableHead>

															<TableBody>
																{cGestor.map((row) => {
																	const {
																		aCapital,
																		aIntCorriente,
																		cuotaPagar,
																		cuotas,
																		fechaEntrega,
																		fechaVencimiento,
																		fullName,
																		id,
																		montoEntregado,
																		totalRecibido
																	} = row;

																	return (
																		<TableRow key={id}>
																			<TableCell
																				align="center"
																				component="th"
																				scope="row"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{id}
																			</TableCell>
																			<TableCell
																				align="left"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{fullName}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					montoEntregado
																				)}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					cuotaPagar
																				)}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{moment(
																					fechaEntrega
																				).format('L')}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{moment(
																					fechaVencimiento
																				).format('L')}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(aCapital)}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					aIntCorriente
																				)}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{new Intl.NumberFormat(
																					'es-NI',
																					{
																						style: 'currency',
																						currency:
																							'NIO'
																					}
																				).format(
																					totalRecibido
																				)}
																			</TableCell>
																			<TableCell
																				align="center"
																				style={{
																					fontSize: 9
																				}}
																			>
																				{cuotas}
																			</TableCell>
																		</TableRow>
																	);
																})}
															</TableBody>
														</Table>
													</TableContainer>

													<hr />
													<Stack
														direction="row"
														flex="row"
														justifyContent="space-around"
													>
														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Creditos Cobrados
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{cGestor.length}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total A Capital
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalCap)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total A Int. Cnte.
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalIC)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Total Cobrado
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(totalCobrado)}
															</Typography>
														</Stack>

														<Stack textAlign="center">
															<Typography
																style={{
																	fontWeight: 'bold',
																	color: '#03a9f4',
																	fontSize: 11
																}}
															>
																Cuotas Cobradas
															</Typography>
															<Typography style={{ fontSize: 11 }}>
																{totalCuotas}
															</Typography>
														</Stack>
													</Stack>
													<hr />
												</Stack>
											);
										})}
									</Stack>
								);
							})}
						</>
					)}

					{gestorList.length === 1 ? (
						<></>
					) : (
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center'
								}}
							>
								Consolidado
							</Typography>

							<hr />

							<Stack direction="row" flex="row" justifyContent="space-around">
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Creditos Cobrados
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{totalCobrosConsol}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total A Capital
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCapConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total A Int. Cnte.
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalICConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Cobrado
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCobradoConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Cuotas Cobradas
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{totalCuotasConsol}
									</Typography>
								</Stack>
							</Stack>
							<hr />
						</Stack>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Recuperación por Cliente'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Sucursal: ${
										selectedSucursal === 't' ? 'Todas' : sucursalName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{rol}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>

						<Container maxWidth={false} sx={{ textAlign: 'center' }}>
							<hr />
							{isEmpty(data) ? (
								<NoData />
							) : (
								<>
									{sucursalList.map((suc) => {
										const sSucursal = data.filter(
											(c) => c.sucursal === suc.description
										);

										return (
											<Stack key={suc.description}>
												<Stack direction="row" spacing={1}>
													<Typography
														sx={{
															color: '#2196f3',
															textAlign: 'left'
														}}
														fontSize={11}
													>
														Sucursal:
													</Typography>
													<Typography
														sx={{
															textAlign: 'left'
														}}
														fontSize={11}
													>
														{`${suc.description}`}
													</Typography>
												</Stack>

												{gestorList.map((gestor) => {
													const cGestor = sSucursal.filter(
														(c) => c.gestor === gestor.id
													);

													let totalCuotas = 0;
													let totalCap = 0;
													let totalIC = 0;
													let totalCobrado = 0;

													cGestor.map((c) => {
														totalCuotas += c.cuotas;
														totalCap += c.aCapital;
														totalIC += c.aIntCorriente;
														totalCobrado += c.totalRecibido;
													});

													return isEmpty(cGestor) ? (
														<></>
													) : (
														<Stack key={gestor.id}>
															<Stack direction="row" spacing={1}>
																<Typography
																	sx={{
																		color: '#2196f3',
																		textAlign: 'left'
																	}}
																	fontSize={11}
																>
																	Gestor:
																</Typography>
																<Typography
																	sx={{
																		textAlign: 'left'
																	}}
																	fontSize={11}
																>
																	{gestor.fullName}
																</Typography>
															</Stack>

															<Divider />

															<TableContainer>
																<Table
																	aria-label="simple table"
																	size="small"
																>
																	<TableHead>
																		<TableRow>
																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				#
																			</TableCell>
																			<TableCell
																				align="left"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Cliente
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Desembolso
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Cuota
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				F.Entrega
																			</TableCell>
																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				F.Vencimiento
																			</TableCell>
																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				A.Capital
																			</TableCell>
																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Abono I.C.
																			</TableCell>

																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Total Recibido
																			</TableCell>
																			<TableCell
																				align="center"
																				className="text-primary fw-bold"
																				style={{
																					fontSize: 11
																				}}
																			>
																				Cuotas
																			</TableCell>
																		</TableRow>
																	</TableHead>

																	<TableBody>
																		{cGestor.map((row) => {
																			const {
																				aCapital,
																				aIntCorriente,
																				cuotaPagar,
																				cuotas,
																				fechaEntrega,
																				fechaVencimiento,
																				fullName,
																				id,
																				montoEntregado,
																				totalRecibido
																			} = row;

																			return (
																				<TableRow key={id}>
																					<TableCell
																						align="center"
																						component="th"
																						scope="row"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{id}
																					</TableCell>
																					<TableCell
																						align="left"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{fullName}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							montoEntregado
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							cuotaPagar
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{moment(
																							fechaEntrega
																						).format(
																							'L'
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{moment(
																							fechaVencimiento
																						).format(
																							'L'
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							aCapital
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							aIntCorriente
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{new Intl.NumberFormat(
																							'es-NI',
																							{
																								style: 'currency',
																								currency:
																									'NIO'
																							}
																						).format(
																							totalRecibido
																						)}
																					</TableCell>
																					<TableCell
																						align="center"
																						style={{
																							fontSize: 9
																						}}
																						className="text-dark"
																					>
																						{cuotas}
																					</TableCell>
																				</TableRow>
																			);
																		})}
																	</TableBody>
																</Table>
															</TableContainer>

															<hr />
															<Stack
																direction="row"
																flex="row"
																justifyContent="space-around"
															>
																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Creditos Cobrados
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{cGestor.length}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total A Capital
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(totalCap)}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total A Int. Cnte.
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(totalIC)}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Total Cobrado
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{new Intl.NumberFormat(
																			'es-NI',
																			{
																				style: 'currency',
																				currency: 'NIO'
																			}
																		).format(totalCobrado)}
																	</Typography>
																</Stack>

																<Stack textAlign="center">
																	<Typography
																		style={{
																			fontWeight: 'bold',
																			color: '#03a9f4',
																			fontSize: 11
																		}}
																	>
																		Cuotas Cobradas
																	</Typography>
																	<Typography
																		style={{ fontSize: 11 }}
																	>
																		{totalCuotas}
																	</Typography>
																</Stack>
															</Stack>
															<hr />
														</Stack>
													);
												})}
											</Stack>
										);
									})}
								</>
							)}

							{gestorList.length === 1 ? (
								<></>
							) : (
								<Stack>
									<Typography
										sx={{
											color: '#2196f3',
											textAlign: 'center'
										}}
									>
										Consolidado
									</Typography>

									<hr />

									<Stack direction="row" flex="row" justifyContent="space-around">
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Creditos Cobrados
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalCobrosConsol}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total A Capital
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalCapConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total A Int. Cnte.
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalICConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Cobrado
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalCobradoConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Cuotas Cobradas
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalCuotasConsol}
											</Typography>
										</Stack>
									</Stack>
									<hr />
								</Stack>
							)}
						</Container>
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default RecuperacionByCLient;
