import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  Paper,
  TextField,
  Button,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import { getRuta, simpleMessage } from "../../../../helpers/Helpers";

import moment from "moment/moment";
import { getToken } from "../../../../api/Account";
import { DataContext } from "../../../../context/DataContext";
import { getSucursalByUserAsync } from "../../../../api/Users";

const SelectorCierreDiario = () => {
  const [fecha, setFecha] = useState(new Date());
  const [isDetallado, setDetallado] = useState(false);

  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("t");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  let ruta = getRuta();
  const token = getToken();

  useEffect(() => {
    getSucursals();
  }, []);

  const verReport = () => {
    if (!moment(fecha).isValid()) {
      simpleMessage("Seleccione una fecha desde válida", "error");
      return;
    }

    var params = `${selectedSucursal}/${moment(fecha).format("YYYY-MM-DD")}`;
    window.open(
      isDetallado
        ? `${ruta}/r-cierrecajad/${params}`
        : `${ruta}/r-cierrecaja/${params}`
    );
  };

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
  };

  const onChangeSucursal = (value) => {
    setSelectedSucursal(value);
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => onChangeSucursal(e.target.value)}
                value={selectedSucursal}
                label="Sucursal"
              >
                {sucursalList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
                <MenuItem key={"t"} value={"t"}>
                  TODAS...
                </MenuItem>
              </Select>
            </FormControl>

            <DesktopDatePicker
              closeOnSelect
              label="Fecha"
              inputFormat="DD/MM/YYYY"
              value={fecha}
              onChange={(newValue) => setFecha(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />

            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={1}
            >
              <FormControlLabel
                labelPlacement="end"
                style={{ color: "#2979ff", fontWeight: "bold" }}
                control={
                  <Checkbox
                    checked={isDetallado}
                    onChange={() => setDetallado(!isDetallado)}
                    sx={{
                      color: "#2979ff",
                      "&.Mui-checked": {
                        color: "#2979ff",
                      },
                    }}
                  />
                }
                label="Informe Detallado"
              />
            </Stack>

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => verReport()}
              style={{
                color: "#11D9D9",
                borderColor: "#11D9D9",
              }}
              size="large"
            >
              Generar Reporte
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default SelectorCierreDiario;
