import React, { useState, useContext } from "react";
import {
  TextField,
  Typography,
  Container,
  Stack,
  Button,
  Paper,
  Grid,
  InputAdornment,
} from "@mui/material";
import { DataContext } from "../../../../context/DataContext";
import { simpleMessage } from "../../../../helpers/Helpers";
import { getToken } from "../../../../api/Account";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { addGarantiaAsync } from "../../../../api/Credits";

const AddGarantia = ({ setShowModal, id, localReload, setLocalReload }) => {
  const [articulo, setArticulo] = useState("");
  const [marca, setMarca] = useState("");
  const [color, setColor] = useState("");
  const [modelo, setModelo] = useState("");
  const [serie, setSerie] = useState("");
  const [valorEstimado, setValorEstimado] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const addNewGarantia = async () => {
    if (validate()) {
      const data = {
        creditId: id,
        articulo,
        marca,
        color,
        modelo,
        serie,
        valorEstimado,
      };
      if (data.marca === "") {
        data.marca = "S/M";
      }

      if (modelo === "") {
        data.modelo = "S/M";
      }

      if (serie === "") {
        data.serie = "S/S";
      }

      setIsLoading(true);
      const result = await addGarantiaAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      simpleMessage("Garantia Agregada...!", "success");
      setLocalReload(!localReload);
      setShowModal(false);
    }
  };

  const validate = () => {
    let isValid = true;
    if (articulo === "") {
      simpleMessage("Debe ingresar un artículo", "error");
      return (isValid = false);
    }

    if (color === "") {
      simpleMessage("Debe ingresar un color", "error");
      return (isValid = false);
    }

    if (valorEstimado === "" || valorEstimado === 0) {
      simpleMessage("Debe ingresar un valor al artículo", "error");
      return (isValid = false);
    }
    return isValid;
  };

  //Devuelve un entero positivo
  const funtionSetValorEstimado = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setValorEstimado(value);
      return;
    }
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={1} direction="row" justifyContent={"center"}>
                <Typography
                  style={{
                    color: "#2979ff",
                  }}
                >
                  CREDITO # :
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {id}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setArticulo(e.target.value.toUpperCase())}
                  value={articulo}
                  label={"Articulo"}
                />
                <TextField
                  fullWidth
                  onChange={(e) => setMarca(e.target.value.toUpperCase())}
                  value={marca}
                  label={"Marca"}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setColor(e.target.value.toUpperCase())}
                  value={color}
                  label={"Color"}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  onChange={(e) => setModelo(e.target.value.toUpperCase())}
                  value={modelo}
                  label={"Modelo"}
                />
                <TextField
                  fullWidth
                  onChange={(e) => setSerie(e.target.value.toUpperCase())}
                  value={serie}
                  label={"Serie"}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) => funtionSetValorEstimado(e.target.value)}
                  value={valorEstimado}
                  label={"Valor estimado"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C$</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
                }
                fullWidth
                onClick={() => addNewGarantia()}
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
                size="large"
              >
                Agregar garantia
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default AddGarantia;
