import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getCobroDiarioReportAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import {
  getGestoresAsync,
  getGestoresBySucursalAccessAsync,
  getSucursalByUserAsync,
} from "../../../api/Users";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";

const CobroDiario = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const [gestorName, setGestorName] = useState("");
  const [gestorList, setGestorList] = useState([]);

  const compRef = useRef();
  const { selectedSucursal, selectedGestor, selectedFecha } = useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        fecha: selectedFecha.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
      };

      setIsLoading(true);
      const result = await getCobroDiarioReportAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    let gestores = await getGestores(selectedSucursal);

    if (selectedGestor === "t") {
      setGestorName("Todos");
    } else {
      let name = gestores.filter(
        (item) => item.id.toString() === selectedGestor
      );

      setGestorName(name[0].fullName);
    }
    return result.data.result;
  };

  const getGestores = async (sucursal) => {
    setIsLoading(true);
    const result =
      sucursal === "t"
        ? await getGestoresBySucursalAccessAsync(token)
        : await getGestoresAsync(token, sucursal);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setGestorList(result.data.result);
    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 4mm; }
`;

  let totalCuptaConsolidado = 0;
  let totalAtrasoConsolidado = 0;
  let totalSaldoConsolidado = 0;

  let consolDCount = 0;
  let consolMCount = 0;
  let consolVCount = 0;

  let consolVSum = 0;

  const exportExcel = () => {
    let exp = [];

    data.map((item) => {
      const dataGestor = gestorList.filter(
        (gestor) => gestor.id === item.gestor
      );
      let gestor = dataGestor[0];
      let obj = {
        cliente: item.fullName,
        direccion: item.address,
        telefono: item.phone,
        fechaEntrega: moment(item.fechaEntrega).format("L"),
        fechaVencimiento: moment(item.fechaVencimiento).format("L"),
        tipo: item.tipo,
        cuota: item.cuota,
        atraso: item.atraso,
        atrasoDias: item.atrasoDias,
        cuotaMasAtraso: item.cuota + item.atraso,
        saldoTotal: item.saldoTotal,
        gestor: gestor.fullName,
        sucursal: item.sucursal,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "cobroDiario");
    XLSX.writeFile(bk, "CobroDiario.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Cobro Diario
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton
                    variant="outlined"
                    // style={{ position: "fixed", right: 20, top: 75 }}
                  >
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Fecha de Cobro: ${moment(selectedFecha).format("L")}`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter(
                  (c) => c.sucursal === suc.description
                );

                return (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>

                    {gestorList.map((gestor) => {
                      const cGestor = sSucursal.filter(
                        (c) => c.gestor === gestor.id
                      );

                      let totalCuota = 0;
                      let totalAtraso = 0;
                      let totalVencido = 0;
                      let totalSaldo = 0;

                      let contadorDia = 0;
                      let contadorMora = 0;
                      let contadorVenc = 0;

                      cGestor.map((c) => {
                        if (c.tipo === "D") {
                          totalCuota += c.cuota;
                          contadorDia += 1;
                        } else if (c.tipo === "M") {
                          contadorMora += 1;
                          totalAtraso += c.atraso;
                        } else {
                          contadorVenc += 1;
                          totalVencido += c.saldoTotal;
                        }

                        totalSaldo += c.saldoTotal;
                      });

                      consolDCount += contadorDia;
                      consolMCount += contadorMora;
                      consolVCount += contadorVenc;

                      totalCuptaConsolidado += totalCuota;
                      totalAtrasoConsolidado += totalAtraso;
                      consolVSum += totalVencido;
                      totalSaldoConsolidado += totalSaldo;

                      return isEmpty(cGestor) ? (
                        <></>
                      ) : (
                        <Stack padding={1} key={gestor.id}>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              sx={{
                                color: "#2196f3",
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              Gestor:
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              {gestor.fullName}
                            </Typography>
                          </Stack>
                          <Divider />

                          <TableContainer>
                            <Table aria-label="simple table" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Cliente
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Dirección
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Teléfono
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    F.Entrega
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    F.Vencimiento
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Tipo
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Cuota
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Atraso
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    A.Dias
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    C + A
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 9 }}
                                  >
                                    Saldo Total
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {cGestor.map((row) => {
                                  return (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.fullName}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.address}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.phone}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(row.fechaEntrega).format("L")}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(row.fechaVencimiento).format(
                                          "L"
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.tipo}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.cuota)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.atraso)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.atrasoDias}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.atraso + row.cuota)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.saldoTotal)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <hr />
                          <Stack
                            direction="row"
                            flex="row"
                            justifyContent="space-around"
                          >
                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Créditos del Día
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {contadorDia}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Cuotas Día
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalCuota)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Créditos en Atraso
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {contadorMora}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Atraso
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {`${new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalAtraso)} = ${(
                                  (totalAtraso / totalSaldo) *
                                  100
                                ).toFixed(2)}%`}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Créditos Vencidos
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {contadorVenc}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Vencido
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {`${new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalVencido)} = ${(
                                  (totalVencido / totalSaldo) *
                                  100
                                ).toFixed(2)}%`}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Saldo
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalSaldo)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <hr />
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </>
          )}

          {selectedGestor !== "t" ? (
            <></>
          ) : (
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                }}
              >
                Consolidado
              </Typography>

              <hr />

              <Stack direction="row" flex="row" justifyContent="space-around">
                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    T. Creditos del Día
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {consolDCount}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    T. Cuotas del Día
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalCuptaConsolidado)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    T. Créditos en Atraso
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {consolMCount}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Atraso
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {`${new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalAtrasoConsolidado)} = ${(
                      (totalAtrasoConsolidado / totalSaldoConsolidado) *
                      100
                    ).toFixed(2)}%`}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Créditos Vencidos
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {consolVCount}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Vencido
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {`${new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(consolVSum)} = ${(
                      (consolVSum / totalSaldoConsolidado) *
                      100
                    ).toFixed(2)}%`}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Saldo
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalSaldoConsolidado)}
                  </Typography>
                </Stack>
              </Stack>

              <hr />
            </Stack>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Cobro Diario"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={10}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={10} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={10} textAlign="left">
                  {`Fecha de Cobro: ${moment(selectedFecha).format("L")}`}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={10}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={10} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={10} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />
            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {sucursalList.map((suc) => {
                  const sSucursal = data.filter(
                    (c) => c.sucursal === suc.description
                  );

                  return (
                    <Stack key={suc.description}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          Sucursal:
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          {`${suc.description}`}
                        </Typography>
                      </Stack>

                      {gestorList.map((gestor) => {
                        const cGestor = sSucursal.filter(
                          (c) => c.gestor === gestor.id
                        );
                        let totalCuota = 0;
                        let totalAtraso = 0;
                        let totalVencido = 0;
                        let totalSaldo = 0;

                        let contadorDia = 0;
                        let contadorMora = 0;
                        let contadorVenc = 0;

                        cGestor.map((c) => {
                          if (c.tipo === "D") {
                            contadorDia += 1;
                          } else if (c.tipo === "M") {
                            contadorMora += 1;
                            totalAtraso += c.atraso;
                          } else {
                            contadorVenc += 1;
                            totalVencido += c.saldoTotal;
                          }
                          totalCuota += c.cuota;
                          totalSaldo += c.saldoTotal;
                        });

                        return isEmpty(cGestor) ? (
                          <></>
                        ) : (
                          <Stack key={gestor.id}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                sx={{
                                  color: "#2196f3",
                                  textAlign: "left",
                                }}
                                fontSize={10}
                              >
                                Gestor:
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={10}
                              >
                                {gestor.fullName}
                              </Typography>
                            </Stack>

                            <TableContainer>
                              <Table aria-label="simple table" size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="left"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Cliente
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Dirección
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Teléfono
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      F.Entrega
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      F.Vencimiento
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Tipo
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Cuota
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Atraso
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      A.Dias
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      C + A
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 8 }}
                                    >
                                      Saldo T.
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cGestor.map((row) => {
                                    return (
                                      <TableRow key={row.id}>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {row.fullName}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {row.address}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {row.phone}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {moment(row.fechaEntrega).format("L")}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {moment(row.fechaVencimiento).format(
                                            "L"
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {row.tipo}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.cuota)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.atraso)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {row.atrasoDias}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.atraso + row.cuota)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 6 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.saldoTotal)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <hr />

                            <Stack
                              direction="row"
                              flex="row"
                              justifyContent="space-around"
                            >
                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Créditos del Día
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {contadorDia}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Cuotas Día
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalCuota)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Créditos en Atraso
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {contadorMora}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Atraso
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {`${new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalAtraso)} = ${(
                                    (totalAtraso / totalSaldo) *
                                    100
                                  ).toFixed(2)}%`}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Créditos Vencidos
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {contadorVenc}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Vencido
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {`${new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalVencido)} = ${(
                                    (totalVencido / totalSaldo) *
                                    100
                                  ).toFixed(2)}%`}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Saldo
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalSaldo)}
                                </Typography>
                              </Stack>
                            </Stack>

                            <hr />
                          </Stack>
                        );
                      })}
                    </Stack>
                  );
                })}
              </>
            )}
          </Container>

          <Container maxWidth="xl" sx={{ textAlign: "center" }}>
            {gestorList.length === 1 ? (
              <></>
            ) : (
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "center",
                  }}
                >
                  Consolidado
                </Typography>

                <hr />

                <Stack direction="row" flex="row" justifyContent="space-around">
                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      T. Creditos del Día
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {consolDCount}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      T. Cuotas del Día
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalCuptaConsolidado)}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      T. Créditos en Atraso
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {consolMCount}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total Atraso
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {`${new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalAtrasoConsolidado)} = ${(
                        (totalAtrasoConsolidado / totalSaldoConsolidado) *
                        100
                      ).toFixed(2)}%`}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Créditos Vencidos
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {consolVCount}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total Vencido
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {`${new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(consolVSum)} = ${(
                        (consolVSum / totalSaldoConsolidado) *
                        100
                      ).toFixed(2)}%`}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total Saldo
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalSaldoConsolidado)}
                    </Typography>
                  </Stack>
                </Stack>

                <hr />
              </Stack>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default CobroDiario;
