import React, { useState, useContext } from "react";
import {
  TextField,
  Divider,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { Save } from "@mui/icons-material";
import { addIntCorrienteAsync } from "../../../api/InteresCorriente";

const AddTasaInteres = ({ setShowModal, localReload, setLocalReload }) => {
  const token = getToken();
  const {setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);
  const [interesC, setInteresC] = useState("");

  //Devuelve un numero decimal
  const setTasaInteres = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString())) {
      setInteresC(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (interesC === "") {
      simpleMessage("Ingrese un valor...", "error");
      return;
    }
    const data = {
      intCorriente: interesC,
    };

    setIsLoading(true);
    const result = await addIntCorrienteAsync(token, data);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Tasa de interes Agregada...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Divider />
      <Container style={{ width: 450 }}>
        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => setTasaInteres(e.target.value.toUpperCase())}
          label={"Interes Corriente"}
          value={interesC}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => saveChangesAsync()}>
                  <Save style={{ color: "#2979ff" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};

export default AddTasaInteres;
