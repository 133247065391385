import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}plazo`;
} else {
  Api = `${REACT_APP_URL}plazo`;
}

export const getPlazoByPeriodicidadAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetPlazoByPeriodicidad/";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url + id).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
