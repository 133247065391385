import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider,
	Paper,
	Grid
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getAccountStatusAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { simpleMessage } from '../../../helpers/Helpers';

const AccountStatus = () => {
	const [data, setData] = useState([]);

	const compRef = useRef();
	const { selectedCredit } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getAccountStatusAsync(token, selectedCredit);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 5mm; }
`;

	const totalAbonado = () => {
		let result = 0;
		if (data.quotes.length > 0) {
			data.quotes.map((item) => (result += item.totalRecibido));
		}
		return result;
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontWeight: 'bold'
						}}
					>
						{slogan}
					</Typography>
					<Typography
						sx={{
							color: '#2196f3',
							textAlign: 'center',
							fontWeight: 'bold'
						}}
						variant="h6"
						component="div"
					>
						Reporte de Estado de Cuenta
					</Typography>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack />
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					<ReactToPrint
						trigger={() => {
							return (
								<IconButton
									variant="outlined"
									style={{ position: 'fixed', right: 20, top: 75 }}
								>
									<PrintRoundedIcon
										style={{ fontSize: 35, color: '#2979ff', width: 35 }}
									/>
								</IconButton>
							);
						}}
						content={() => compRef.current}
						pageStyle={pageStyle}
					/>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<Paper
								elevation={10}
								style={{
									padding: 20
								}}
							>
								<Stack direction="row" justifyContent="space-between">
									<Stack spacing={2}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Crédito #:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.id}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Cliente
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.client.fullName}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Monto Entregado:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(data.montoEntregado)}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Monto a Pagar:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(data.montoPagar)}
											</Typography>
										</Stack>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Promedio Credito:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.promedio.toFixed(2)}
											</Typography>
										</Stack>
									</Stack>

									<Stack spacing={2}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Interes Corriente:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{`${new Intl.NumberFormat('es-NI', {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2
												}).format(
													data.tasaInteresCorriente.intCorriente
												)} %`}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Periodicidad
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.periocidad.description}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Plazo
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.plazo.description}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Cuota
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(data.cuotaPagar)}
											</Typography>
										</Stack>
									</Stack>

									<Stack spacing={2}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Fecha Primera Cuota:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{moment(data.fechaPrimeraCuota).format('L')}
											</Typography>
										</Stack>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Fecha de Entrega:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{moment(data.fechaEntrega).format('L')}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Fecha de Vencimiento:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{moment(data.fechaVencimiento).format('L')}
											</Typography>
										</Stack>

										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Fecha de Cancelación:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.isCanceled
													? moment(data.fechaCancelacion).format('L')
													: 'No Cancelado'}
											</Typography>
										</Stack>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'center',
													fontWeight: 'bold'
												}}
											>
												Promedio Cliente:
											</Typography>
											<Typography
												sx={{
													textAlign: 'center'
												}}
											>
												{data.client.promedio.toFixed(2)}
											</Typography>
										</Stack>
									</Stack>
								</Stack>
							</Paper>

							<Grid container spacing={2} marginTop={2}>
								<Grid item sx={12} sm={12} md={6}>
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold'
											}}
											fontSize={15}
										>
											Plan de Pagos
										</Typography>
										<hr />

										<TableContainer>
											<Table aria-label="simple table" size="small">
												<TableHead>
													<TableRow>
														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															#
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															F.Pago
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															D.Mora
														</TableCell>
														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															Cuota
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															S.Principal
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															S.Interes C.
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															Cancelado
														</TableCell>
													</TableRow>
												</TableHead>

												<TableBody>
													{data.payments.map((row) => {
														var hoy = new Date();
														var fecha = new Date(row.fechaPago);
														var Difference_In_Time =
															hoy.getTime() - fecha.getTime();
														var Difference_In_Days =
															Difference_In_Time / (1000 * 3600 * 24);

														return (
															<TableRow key={row.id}>
																<TableCell
																	align="center"
																	component="th"
																	scope="row"
																	style={{ fontSize: 9 }}
																>
																	{row.numeroCuota}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{moment(row.fechaPago).format(
																		'L'
																	)}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{row.isCanceled
																		? row.diasMora
																		: Difference_In_Days > 0
																		? Difference_In_Days.toFixed(
																				0
																		  )
																		: 0}
																</TableCell>
																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{row.cuotaPrincipal}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(row.saldoPrincipal)}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(
																		row.saldoInteresCorriente
																	)}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{row.isCanceled ? 'SI' : 'NO'}
																</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Paper>
								</Grid>

								<Grid item sx={12} sm={12} md={6}>
									<Paper
										style={{
											padding: 10
										}}
									>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold'
											}}
											fontSize={15}
										>
											Abonos
										</Typography>
										<hr />

										<TableContainer>
											<Table aria-label="simple table" size="small">
												<TableHead>
													<TableRow>
														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															Transacción
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															Fecha Pago
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															Concepto
														</TableCell>

														<TableCell
															align="center"
															className="text-primary fw-bold"
															style={{ fontSize: 10 }}
														>
															Total Recibido
														</TableCell>
													</TableRow>
												</TableHead>

												<TableBody>
													{data.quotes.map((row) => {
														return (
															<TableRow key={row.id}>
																<TableCell
																	align="center"
																	component="th"
																	scope="row"
																	style={{ fontSize: 9 }}
																>
																	{row.id}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{moment(row.fechaPago).format(
																		'L'
																	)}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{row.concepto}
																</TableCell>

																<TableCell
																	align="center"
																	style={{ fontSize: 9 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(row.totalRecibido)}
																</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Paper>
								</Grid>
							</Grid>

							<Paper style={{ marginTop: 20, padding: 20 }}>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems="center"
									justifyContent={'space-between'}
									divider={<Divider orientation="vertical" flexItem />}
								>
									<Stack direction="column" spacing={1}>
										<Typography>Monto a Pagar</Typography>
										<Typography variant="h6" style={{ color: '#2979ff' }}>
											{`${new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(data.montoPagar)}`}
										</Typography>
									</Stack>

									<Stack direction="column" spacing={1}>
										<Typography>Total Abonado</Typography>
										<Typography variant="h6" style={{ color: '#00a152' }}>
											{`${new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(totalAbonado())}`}
										</Typography>
									</Stack>

									<Stack direction="column" spacing={1}>
										<Typography>Saldo Pendiente</Typography>
										<Typography variant="h6" style={{ color: '#f50057' }}>
											{`${new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(data.montoPagar - totalAbonado())}`}
										</Typography>
									</Stack>
								</Stack>
							</Paper>
						</>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Estado de Cuenta'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack />
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>

					{isEmpty(data) ? (
						<></>
					) : (
						<Container maxWidth={false} sx={{ textAlign: 'center' }}>
							<hr />

							<Stack direction="row" justifyContent="space-between">
								<Stack spacing={1}>
									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Crédito #:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.id}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Cliente
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.client.fullName}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Monto Entregado:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(data.montoEntregado)}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Monto a Pagar:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(data.montoPagar)}
										</Typography>
									</Stack>
									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Promedio Credito:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.promedio.toFixed(2)}
										</Typography>
									</Stack>
								</Stack>

								<Stack spacing={1}>
									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Interes Corriente:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{`${new Intl.NumberFormat('es-NI', {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2
											}).format(data.tasaInteresCorriente.intCorriente)} %`}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Periodicidad
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.periocidad.description}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Plazo
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.plazo.description}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Cuota
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{new Intl.NumberFormat('es-NI', {
												style: 'currency',
												currency: 'NIO'
											}).format(data.cuotaPagar)}
										</Typography>
									</Stack>
								</Stack>

								<Stack spacing={1}>
									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Fecha Primera Cuota:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{moment(data.fechaPrimeraCuota).format('L')}
										</Typography>
									</Stack>
									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Fecha de Entrega:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{moment(data.fechaEntrega).format('L')}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Fecha de Vencimiento:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{moment(data.fechaVencimiento).format('L')}
										</Typography>
									</Stack>

									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Fecha de Cancelación:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.isCanceled
												? moment(data.fechaCancelacion).format('L')
												: 'No Cancelado'}
										</Typography>
									</Stack>
									<Stack direction="row" spacing={1}>
										<Typography
											sx={{
												color: '#2196f3',
												textAlign: 'center',
												fontWeight: 'bold',
												fontSize: 11
											}}
										>
											Promedio Cliente:
										</Typography>
										<Typography
											sx={{
												textAlign: 'center',
												fontSize: 11
											}}
										>
											{data.client.promedio.toFixed(2)}
										</Typography>
									</Stack>
								</Stack>
							</Stack>

							<hr />

							<Grid container spacing={1}>
								<Grid item sx={12} sm={12} md={6}>
									<Typography
										sx={{
											color: '#2196f3',
											textAlign: 'center',
											fontWeight: 'bold'
										}}
										fontSize={12}
									>
										Plan de Pagos
									</Typography>
									<hr />

									<TableContainer>
										<Table aria-label="simple table" size="small">
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														#
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														F.Pago
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														Días
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														D.Mora
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														S.Princ.
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														S.Int. C.
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														Cancelado
													</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{data.payments.map((row) => {
													var hoy = new Date();
													var fecha = new Date(row.fechaPago);
													var Difference_In_Time =
														hoy.getTime() - fecha.getTime();
													var Difference_In_Days =
														Difference_In_Time / (1000 * 3600 * 24);

													return (
														<TableRow key={row.id}>
															<TableCell
																align="center"
																component="th"
																scope="row"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{row.numeroCuota}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{moment(row.fechaPago).format('L')}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{row.periodicidad}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{row.isCanceled
																	? row.diasMora
																	: Difference_In_Days > 0
																	? Difference_In_Days.toFixed(0)
																	: 0}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.saldoPrincipal)}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(
																	row.saldoInteresCorriente
																)}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{row.isCanceled ? 'SI' : 'NO'}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>

								<Grid item sx={12} sm={12} md={6}>
									<Typography
										sx={{
											color: '#2196f3',
											textAlign: 'center',
											fontWeight: 'bold'
										}}
										fontSize={12}
									>
										Abonos
									</Typography>
									<hr />

									<TableContainer>
										<Table aria-label="simple table" size="small">
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														Transacción
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														Fecha Pago
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														Concepto
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 10 }}
													>
														Total Recibido
													</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{data.quotes.map((row) => {
													return (
														<TableRow key={row.id}>
															<TableCell
																align="center"
																component="th"
																scope="row"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{row.id}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{moment(row.fechaPago).format('L')}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{row.concepto}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 9 }}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.totalRecibido)}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>

							<hr />

							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								justifyContent={'space-between'}
								divider={<Divider orientation="vertical" flexItem />}
							>
								<Stack direction="column" spacing={1}>
									<Typography>Monto a Pagar</Typography>
									<Typography variant="h6" style={{ color: '#2979ff' }}>
										{`${new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(data.montoPagar)}`}
									</Typography>
								</Stack>

								<Stack direction="column" spacing={1}>
									<Typography>Total Abonado</Typography>
									<Typography variant="h6" style={{ color: '#00a152' }}>
										{`${new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalAbonado())}`}
									</Typography>
								</Stack>

								<Stack direction="column" spacing={1}>
									<Typography>Saldo Pendiente</Typography>
									<Typography variant="h6" style={{ color: '#f50057' }}>
										{`${new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(data.montoPagar - totalAbonado())}`}
									</Typography>
								</Stack>
							</Stack>
						</Container>
					)}
				</PrintReport>
			</div>
		</div>
	);
};

export default AccountStatus;
