import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
  Paper,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getEstadoResultadoAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";

const EstadoResultado = () => {
  const [data, setData] = useState([]);

  const compRef = useRef();
  const { selectedSucursal, fechaDesde, fechaHasta } = useParams();

  const [sumIngresos, setSumIngresos] = useState(0);

  const [gastosAdministrativos, setGastosAdministrativos] = useState([]);
  const [gastosOperativos, setGastosOperativos] = useState([]);
  const [otrosGastos, setOtrosGastos] = useState([]);

  const [sumGastosAdministrativos, setSumGastosAdministrativos] = useState(0);
  const [sumGastosOperativos, setSumGastosOperativos] = useState(0);
  const [sumOtrosGastos, setSumOtrosGastos] = useState(0);

  const [sumEgresos, setSumEgres] = useState(0);
  const [sumOtrosIngresos, setSumOtrosIngresos] = useState(0);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        desde: fechaDesde.toString(),
        hasta: fechaHasta.toString(),
      };

      setIsLoading(true);
      const result = await getEstadoResultadoAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);

      setSumIngresos(
        result.data.result.recuperacionCapital +
          result.data.result.recuperacionInteres +
          result.data.result.otrosIngresos +
          result.data.result.ingresosBanco
      );

      let tga = 0;
      let tgo = 0;
      let tog = 0;

      setGastosAdministrativos(result.data.result.gastosAdministrativos);
      result.data.result.gastosAdministrativos.map(
        (item) => (tga += item.monto)
      );
      setSumGastosAdministrativos(tga);

      setGastosOperativos(result.data.result.gastosOperativos);
      result.data.result.gastosOperativos.map((item) => (tgo += item.monto));
      setSumGastosOperativos(tgo);

      setOtrosGastos(result.data.result.otrosGastos);
      result.data.result.otrosGastos.map((item) => (tog += item.monto));
      setSumOtrosGastos(tog);

      setSumEgres(tga + tgo + tog);

      setData(result.data.result);
    })();
  }, []);

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
   @page {   margin: 4mm; }
`;

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);

    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );
    return result.data.result;
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {slogan}
          </Typography>
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h6"
            component="div"
          >
            Reporte Estado de Resultado
          </Typography>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Desde:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaDesde).format("L")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Hasta:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaHasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "fixed", right: 20, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              <Paper
                elevation={10}
                style={{
                  padding: 20,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="space-around"
                >
                  <Stack spacing={2}>
                    <Paper
                      elevation={10}
                      style={{
                        padding: 20,
                      }}
                    >
                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                                colSpan={1}
                              >
                                Ingresos
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                              ></TableCell>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key={1}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Recuperación Capital
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.recuperacionCapital)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            <TableRow key={2}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Recuperación Interes Corriente
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.recuperacionInteres)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            <TableRow key={3}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Ingreso de Bancos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.ingresosBanco)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            <TableRow key={4}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Otros Ingresos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.otrosIngresos)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            {/* Total Ingresos */}
                            <TableRow key={6}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Total Ingreso
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(sumIngresos)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>

                    <Paper
                      elevation={10}
                      style={{
                        padding: 20,
                      }}
                    >
                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                                colSpan={1}
                              >
                                Egresos
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                              ></TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow key={10}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 10 }}
                                colSpan={3}
                                className="text-primary fw-bold"
                              >
                                Gastos Administrativos
                              </TableCell>
                            </TableRow>

                            <TableRow key={11}>
                              <TableContainer>
                                <Table aria-label="simple table" size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        align="left"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 9 }}
                                        colSpan={1}
                                      >
                                        Concepto
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 9 }}
                                        colSpan={1}
                                      >
                                        Monto
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {gastosAdministrativos.map((row) => {
                                      return (
                                        <TableRow key={`${row.id}ga`}>
                                          <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{ fontSize: 9 }}
                                          >
                                            {row.description}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 9 }}
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(row.monto)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableRow>

                            <TableRow key={12}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Total Gastos Administrativos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(sumGastosAdministrativos)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            <TableRow key={13}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 10 }}
                                colSpan={3}
                                className="text-primary fw-bold"
                              >
                                Gastos Operativos
                              </TableCell>
                            </TableRow>

                            <TableRow key={14}>
                              <TableContainer>
                                <Table aria-label="simple table" size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        align="left"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 9 }}
                                        colSpan={1}
                                      >
                                        Concepto
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 9 }}
                                        colSpan={1}
                                      >
                                        Monto
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {gastosOperativos.map((row) => {
                                      return (
                                        <TableRow key={`${row.id}ga`}>
                                          <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{ fontSize: 9 }}
                                          >
                                            {row.description}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 9 }}
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(row.monto)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableRow>

                            <TableRow key={15}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Total Gastos Operativos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(sumGastosOperativos)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            <TableRow key={16}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 10 }}
                                colSpan={3}
                                className="text-primary fw-bold"
                              >
                                Otros Gastos
                              </TableCell>
                            </TableRow>

                            <TableRow key={17}>
                              <TableContainer>
                                <Table aria-label="simple table" size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        align="left"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 9 }}
                                        colSpan={1}
                                      >
                                        Concepto
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 9 }}
                                        colSpan={1}
                                      >
                                        Monto
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {otrosGastos.map((row) => {
                                      return (
                                        <TableRow key={`${row.id}ga`}>
                                          <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{ fontSize: 9 }}
                                          >
                                            {row.description}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 9 }}
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(row.monto)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableRow>

                            <TableRow key={18}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 11 }}
                              >
                                Total Otros Gastos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(sumOtrosGastos)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                            </TableRow>

                            {/* Total Egresos */}
                            <TableRow key={19}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Total Egresos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(sumEgresos)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Stack>

                  <Paper
                    elevation={10}
                    style={{
                      padding: 20,
                    }}
                  >
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 14 }}
                              colSpan={1}
                            >
                              Utilidad / Pérdida del Período
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 14 }}
                            ></TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 14 }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {/* Utilidad */}

                          <TableRow key={1}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Ingresos - Egresos
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(sumIngresos - sumEgresos)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={2}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Utilidad Neta
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.recuperacionInteres - sumEgresos)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={3}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Cuentas por Cobrar
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.cuentasPorCobrar)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={4}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Desembolsos Mes Anterior
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.desembolsosLast)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={5}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Desembolsos
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.desembolsos)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={6}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Perdidas por Liquidacion de Crèditos
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.perdidasLiquidacion)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={7}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Saldo Incial Caja
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.cajaInicial)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={8}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Saldo Final Caja
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(data.cajaFinal)}
                            </TableCell>
                          </TableRow>

                          <TableRow key={9}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-primary fw-bold"
                            >
                              Diferencia
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: 11 }}
                            ></TableCell>
                            <TableCell align="center" style={{ fontSize: 14 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(
                                data.cajaFinal - Math.abs(data.cajaInicial)
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Stack>
              </Paper>
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte Estado de Resultado"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container
            fixed
            maxWidth="xl"
            sx={{ textAlign: "center", marginTop: 1 }}
          >
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Desde:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaDesde).format("L")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Hasta:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaHasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>

            <Container maxWidth={false} sx={{ textAlign: "center" }}>
              <hr />

              {isEmpty(data) ? (
                <NoData />
              ) : (
                <>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent="space-around"
                  >
                    <Stack spacing={2}>
                      <>
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                  style={{ fontSize: 14 }}
                                  colSpan={1}
                                >
                                  Ingresos
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                  style={{ fontSize: 14 }}
                                ></TableCell>
                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                  style={{ fontSize: 14 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key={1}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  Recuperación Capital
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(data.recuperacionCapital)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              <TableRow key={2}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  Recuperación Interes Corriente
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(data.recuperacionInteres)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              <TableRow key={3}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  Ingreso de Bancos
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(data.ingresosBanco)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              <TableRow key={4}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  Otros Ingresos
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(data.otrosIngresos)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              {/* Total Ingresos */}
                              <TableRow key={6}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 14 }}
                                  className="text-primary fw-bold"
                                >
                                  Total Ingreso
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 14 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(sumIngresos)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>

                      <>
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                  style={{ fontSize: 14 }}
                                  colSpan={1}
                                >
                                  Egresos
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                  style={{ fontSize: 14 }}
                                ></TableCell>

                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                  style={{ fontSize: 14 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              <TableRow key={10}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 10 }}
                                  colSpan={3}
                                  className="text-primary fw-bold"
                                >
                                  Gastos Administrativos
                                </TableCell>
                              </TableRow>

                              <TableRow key={11}>
                                <TableContainer>
                                  <Table aria-label="simple table" size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          align="left"
                                          className="text-primary fw-bold"
                                          style={{ fontSize: 9 }}
                                          colSpan={1}
                                        >
                                          Concepto
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-primary fw-bold"
                                          style={{ fontSize: 9 }}
                                          colSpan={1}
                                        >
                                          Monto
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {gastosAdministrativos.map((row) => {
                                        return (
                                          <TableRow key={`${row.id}ga`}>
                                            <TableCell
                                              align="left"
                                              component="th"
                                              scope="row"
                                              style={{ fontSize: 9 }}
                                              className="text-dark"
                                            >
                                              {row.description}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{ fontSize: 9 }}
                                              className="text-dark"
                                            >
                                              {new Intl.NumberFormat("es-NI", {
                                                style: "currency",
                                                currency: "NIO",
                                              }).format(row.monto)}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </TableRow>

                              <TableRow key={12}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  Total Gastos Administrativos
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(sumGastosAdministrativos)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              <TableRow key={13}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 10 }}
                                  colSpan={3}
                                  className="text-primary fw-bold"
                                >
                                  Gastos Operativos
                                </TableCell>
                              </TableRow>

                              <TableRow key={14}>
                                <TableContainer>
                                  <Table aria-label="simple table" size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          align="left"
                                          className="text-primary fw-bold"
                                          style={{ fontSize: 9 }}
                                          colSpan={1}
                                        >
                                          Concepto
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-primary fw-bold"
                                          style={{ fontSize: 9 }}
                                          colSpan={1}
                                        >
                                          Monto
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {gastosOperativos.map((row) => {
                                        return (
                                          <TableRow key={`${row.id}ga`}>
                                            <TableCell
                                              align="left"
                                              component="th"
                                              scope="row"
                                              style={{ fontSize: 9 }}
                                              className="text-dark"
                                            >
                                              {row.description}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{ fontSize: 9 }}
                                              className="text-dark"
                                            >
                                              {new Intl.NumberFormat("es-NI", {
                                                style: "currency",
                                                currency: "NIO",
                                              }).format(row.monto)}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </TableRow>

                              <TableRow key={15}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  Total Gastos Operativos
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(sumGastosOperativos)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              <TableRow key={16}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 10 }}
                                  colSpan={3}
                                  className="text-primary fw-bold"
                                >
                                  Otros Gastos
                                </TableCell>
                              </TableRow>

                              <TableRow key={17}>
                                <TableContainer>
                                  <Table aria-label="simple table" size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          align="left"
                                          className="text-primary fw-bold"
                                          style={{ fontSize: 9 }}
                                          colSpan={1}
                                        >
                                          Concepto
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className="text-primary fw-bold"
                                          style={{ fontSize: 9 }}
                                          colSpan={1}
                                        >
                                          Monto
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {otrosGastos.map((row) => {
                                        return (
                                          <TableRow key={`${row.id}ga`}>
                                            <TableCell
                                              align="left"
                                              component="th"
                                              scope="row"
                                              style={{ fontSize: 9 }}
                                              className="text-dark"
                                            >
                                              {row.description}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{ fontSize: 9 }}
                                              className="text-dark"
                                            >
                                              {new Intl.NumberFormat("es-NI", {
                                                style: "currency",
                                                currency: "NIO",
                                              }).format(row.monto)}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </TableRow>

                              <TableRow key={18}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  Total Otros Gastos
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(sumOtrosGastos)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                              </TableRow>

                              {/* Total Egresos */}
                              <TableRow key={19}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 14 }}
                                  className="text-primary fw-bold"
                                >
                                  Total Egresos
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                ></TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 14 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(sumEgresos)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    </Stack>

                    <>
                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                                colSpan={1}
                              >
                                Utilidad / Pérdida del Período
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                              ></TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 14 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {/* Utilidad */}

                            <TableRow key={1}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Ingresos - Egresos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(sumIngresos - sumEgresos)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={2}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Utilidad Neta
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(
                                  data.recuperacionInteres - sumEgresos
                                )}
                              </TableCell>
                            </TableRow>

                            <TableRow key={3}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Cuentas por Cobrar
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.cuentasPorCobrar)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={4}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Desembolsos Mes Anterior
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.desembolsosLast)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={5}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Desembolsos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.desembolsos)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={6}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Perdidas por Liquidacion de Crèditos
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.perdidasLiquidacion)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={7}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Saldo Incial Caja
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.cajaInicial)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={8}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Saldo Final Caja
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(data.cajaFinal)}
                              </TableCell>
                            </TableRow>

                            <TableRow key={9}>
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ fontSize: 14 }}
                                className="text-primary fw-bold"
                              >
                                Diferencia
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ fontSize: 11 }}
                              ></TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: 14 }}
                                className="text-dark"
                              >
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(
                                  data.cajaFinal - Math.abs(data.cajaInicial)
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  </Stack>
                </>
              )}
            </Container>
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default EstadoResultado;
