import React, { createContext, useState } from "react";
export const DataContext = createContext();

//Si es Igual a produccion es de dimas
// lo pongo !== de prod para subirlo a mi demo
let idDev = process.env.NODE_ENV === "production" ? true : false;

export const DataProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(null);
  const [version, setVersion] = useState("v-6.0.0");

  const [title, setTitle] = useState(
    idDev ? "A&Z SERVICIOS FINANCIEROS" : "CrediApp"
  );

  const [docTitle, setDocTitle] = useState(idDev ? "A&Z" : "CrediApp");
  const [slogan, setSlogan] = useState(
    idDev ? "¡Juntos con vos!" : "¡Desarrollando tu Futuro!"
  );

  const [reload, setReload] = useState(false);
  const [access, setAccess] = useState([]);
  const [isUnautorized, setIsUnautorized] = useState(false);
  const [isServerOff, setIsServerOff] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState(0);

  const [icon, setIcon] = useState(
    require(idDev
      ? "../components/media/Icon.png"
      : "../components/media/logo.png")
  );

  const [favicon] = useState(
    require(idDev
      ? "../components/media/faviconProd.ico"
      : "../components/media/faviconCA.ico")
  );
  return (
    <DataContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        isLoading,
        setIsLoading,
        isLogged,
        setIsLogged,
        title,
        setTitle,
        reload,
        setReload,
        access,
        setAccess,
        isUnautorized,
        setIsUnautorized,
        slogan,
        setSlogan,
        docTitle,
        setDocTitle,
        icon,
        setIcon,
        version,
        setVersion,
        favicon,
        isServerOff,
        setIsServerOff,
        selectedSucursal,
        setSelectedSucursal,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
