import React, { useState, useContext, useEffect } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { getToken } from '../../../api/Account';
import { DataContext } from '../../../context/DataContext';
import { getRuta, simpleMessage } from '../../../helpers/Helpers';
import {
	Container,
	Stack,
	Paper,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	TextField,
	Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import { getPlazoByPeriodicidadAsync } from '../../../api/Plazo';
import { getPeriodicidadListAsync } from '../../../api/Periodicidad';
import { getInteresListAsync } from '../../../api/InteresCorriente';
import { reestructurarCreditAsync } from '../../../api/Credits';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Reestructuracion = ({ id, setShowModal }) => {
	const [fechaPrimeraCuota, setFechaPrimeraCuota] = useState(new Date());
	const [fechaSegundaCuota, setFechaSegundaCuota] = useState(new Date());

	const [periodicidadList, setPeriodicidadList] = useState([]);
	const [selectedPeriodicidad, setSelectedPeriodicidad] = useState('');

	const [plazoList, setPlazoList] = useState([]);
	const [selectedPlazo, setSelectedPlazo] = useState('');

	const [interesCorrienteList, setInteresCorrienteList] = useState([]);
	const [selectedInteresCorriente, setSelectedInteresCorriente] = useState('');

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();
	let navigate = useNavigate();

	useEffect(() => {
		getPeriodicidad();
		getTazaInteres();
	}, []);

	const getPeriodicidad = async () => {
		setIsLoading(true);
		const resultPer = await getPeriodicidadListAsync(token);
		if (!resultPer.statusResponse) {
			setIsLoading(false);
			if (resultPer.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(resultPer.error, 'error');
			return;
		}
		setPeriodicidadList(resultPer.data.result);
		setIsLoading(false);
	};

	const getTazaInteres = async () => {
		setIsLoading(true);
		const resultIC = await getInteresListAsync(token);
		if (!resultIC.statusResponse) {
			setIsLoading(false);
			if (resultIC.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(resultIC.error, 'error');
			return;
		}
		setInteresCorrienteList(resultIC.data.result);
		setIsLoading(false);
	};

	const handleChangePeriodicidad = async (event) => {
		const selected = periodicidadList.filter((item) => item.id == event.target.value);
		let dias = selected[0].cuotaDias;

		var newDate = new Date();
		newDate.setDate(newDate.getDate() + dias);

		setFechaPrimeraCuota(newDate);
		setPlazoList([]);
		setSelectedPeriodicidad(event.target.value);

		if (event.target.value !== '') {
			setIsLoading(true);
			const result = await getPlazoByPeriodicidadAsync(token, event.target.value);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setPlazoList(result.data.result);
			setIsLoading(false);
		} else {
			setPlazoList([]);
		}
	};

	const saveChanges = async () => {
		if (validate()) {
			const datos = {
				creditId: id,
				periocidadId: selectedPeriodicidad,
				plazoId: selectedPlazo,
				interesCorrienteId: selectedInteresCorriente,
				fechaPrimeraCuota: moment(fechaPrimeraCuota).format('YYYY-MM-DD').toString(),
				fechaSegundaCuota: moment(fechaSegundaCuota).format('YYYY-MM-DD').toString()
			};
			setIsLoading(true);
			const result = await reestructurarCreditAsync(token, datos);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			navigate(`${ruta}/credits/`);
			setIsLoading(false);
			setShowModal(false);
			simpleMessage('Crédito reestructurado...!', 'success');
		}
	};

	const validate = () => {
		let isValid = true;

		if (fechaPrimeraCuota === '') {
			simpleMessage('Debe ingresar fecha de primera cuota', 'error');
			return (isValid = false);
		}

		if (selectedPlazo === '') {
			simpleMessage('Debe seleccionar un plazo', 'error');
			return (isValid = false);
		}

		if (selectedInteresCorriente === '') {
			simpleMessage('Debe seleccionar una tasa de interes', 'error');
			return (isValid = false);
		}

		return isValid;
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack spacing={2}>
						<DesktopDatePicker
							closeOnSelect
							label="Fecha Primera Cuota"
							inputFormat="DD/MM/YYYY"
							value={fechaPrimeraCuota}
							onChange={(newValue) => setFechaPrimeraCuota(newValue)}
							renderInput={(params) => <TextField {...params} fullWidth />}
						/>

						{selectedPeriodicidad === 2 ? (
							<DesktopDatePicker
								closeOnSelect
								label="Fecha Segunda Cuota"
								inputFormat="DD/MM/YYYY"
								value={fechaSegundaCuota}
								onChange={(newValue) => setFechaSegundaCuota(newValue)}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						) : (
							<></>
						)}

						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">
								Periodicidad
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={handleChangePeriodicidad}
								value={selectedPeriodicidad}
								label="Periodicidad"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Periodo</em>
								</MenuItem>
								{periodicidadList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.description}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">Plazo</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => setSelectedPlazo(e.target.value)}
								value={selectedPlazo}
								label="Plazo"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Plazo</em>
								</MenuItem>
								{plazoList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.description}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">
								Tasa de interes
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => setSelectedInteresCorriente(e.target.value)}
								value={selectedInteresCorriente}
								label="Tasa de interes"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione una Tasa de Interes</em>
								</MenuItem>
								{interesCorrienteList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{`${item.intCorriente.toFixed(2)} %`}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon
									icon={faSave}
									style={{ marginRight: 20, padding: 9 }}
								/>
							}
							fullWidth
							onClick={() => saveChanges()}
							style={{
								borderRadius: 30,
								color: '#00a152',
								borderColor: '#00a152'
							}}
							size="large"
						>
							Reestrucurar
						</Button>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default Reestructuracion;
