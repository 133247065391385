import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, MenuItem, InputLabel, Grid, FormControl, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../../../api/Account';
import { DataContext } from '../../../../context/DataContext';
import {
	getCommunitiesByMunAsync,
	getMunicipalitiesByDeptopAsync,
	getOnlyDepartmentListAsync
} from '../../../../api/Locations';
import { getRuta, simpleMessage, validateCedula } from '../../../../helpers/Helpers';
import { updateFiadorAsync } from '../../../../api/Credits';

const EditarFiador = ({ setShowModal, selectedfiador, isUpdate, setIsUpdate }) => {
	
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const [departmentList, setDepartmentList] = useState([]);
	const [selectedDepartment, setselectedDepartment] = useState('');

	const [MunicipalitiesList, setMunicipalitiesList] = useState([]);
	const [selectedMunicipality, setselectedMunicipality] = useState('');

	const [communitiesList, setCommunitiesList] = useState([]);
	const [selectedCommunity, setselectedCommunity] = useState('');

	const [fullName, setFullName] = useState('');
	const [identification, setIdentification] = useState('');
	const [phone, setPhone] = useState('');

	const [sex, setSex] = useState('');
	const [civilStatus, setCivilStatus] = useState('');

	const [address, setAddress] = useState('');
	const [profession, setProfession] = useState('');

	const [company, setCompany] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');

	const [actividadEconomica, setActividadEconomica] = useState('');

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getOnlyDepartmentListAsync(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setDepartmentList(result.data.result);
			getMunicipalitiesByDepto(selectedfiador.community.municipality.department.id);
			getComumunityByMun(selectedfiador.community.municipality.id);
			loadData(selectedfiador);
			setIsLoading(false);
		})();
	}, []);

	const loadData = (fiador) => {
		setFullName(fiador.fullName);
		setPhone(fiador.phone);
		setIdentification(fiador.identification);
		setSex(fiador.sex === 'MASCULINO' ? 0 : 1);
		setCivilStatus(getCivilStatus(fiador.civilStatus));

		setselectedDepartment(fiador.community.municipality.department.id);
		setselectedMunicipality(fiador.community.municipality.id);
		setselectedCommunity(fiador.community.id);
		setAddress(fiador.address);
		setProfession(fiador.profesion);
		setActividadEconomica(fiador.actividadEconomica);
		setCompany(fiador.company);
		setCompanyAddress(fiador.companyAddress);
	};

	const getMunicipalitiesByDepto = async (id) => {
		const resultMun = await getMunicipalitiesByDeptopAsync(token, id);
		if (!resultMun.statusResponse) {
			setIsLoading(false);
			if (resultMun.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(resultMun.error, 'error');
			return;
		}
		setMunicipalitiesList(resultMun.data.result);
	};

	const getComumunityByMun = async (id) => {
		const resultComm = await getCommunitiesByMunAsync(token, id);
		if (!resultComm.statusResponse) {
			setIsLoading(false);
			if (resultComm.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(resultComm.error, 'error');
			return;
		}
		setCommunitiesList(resultComm.data.result);
	};

	const validate = () => {
		let isValid = true;
		if (fullName.length === 0) {
			simpleMessage('Debe ingresar un nombre', 'error');
			return (isValid = false);
		}

		if (isEmpty(identification)) {
			simpleMessage('Debe ingresar una cédula', 'error');
			return (isValid = false);
		}

		if (!validateCedula(identification)) {
			simpleMessage('Debe ingresar una cédula valida', 'error');
			return (isValid = false);
		}

		if (isEmpty(phone)) {
			simpleMessage('Debe ingresar un teléfono', 'error');
			return (isValid = false);
		}

		if (sex === '') {
			simpleMessage('Debe seleccionar un sexo', 'error');
			return (isValid = false);
		}

		if (civilStatus === '') {
			simpleMessage('Debe seleccionar un estado civil', 'error');
			return (isValid = false);
		}

		if (selectedCommunity === '') {
			simpleMessage('Debe seleccionar una comunidad', 'error');
			return (isValid = false);
		}

		if (address.length === 0) {
			simpleMessage('error', 'Debe ingresar una direccion', 'error');
			return (isValid = false);
		}

		if (isEmpty(actividadEconomica)) {
			simpleMessage('Debe ingresar una actividad economica', 'error');
			return (isValid = false);
		}
		return isValid;
	};

	const saveChanges = async () => {
		if (validate()) {
			const datos = {
				id: selectedfiador.id,
				fullName: fullName.trim(),
				identification: identification,
				phone: phone.trim(),
				sex: sex === 0 ? 'MASCULINO' : 'FEMENINO',
				civilStatus:
					civilStatus === 0
						? sex === 0
							? 'SOLTERO'
							: 'SOLTERA'
						: civilStatus === 1
						? sex === 0
							? 'CASADO'
							: 'CASADA'
						: 'UNION LIBRE',
				communityId: selectedCommunity,
				address: address.trim(),
				company: company.trim(),
				profesion: profession.trim(),
				companyAddress: companyAddress.trim(),
				actividadEconomica: actividadEconomica.trim()
			};

			setIsLoading(true);
			const result = await updateFiadorAsync(token, datos);
			if (!result.statusResponse) {
				setIsLoading(false);

				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}

				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);

			simpleMessage('Fiador actualizado...!', 'success');
			clearData();
			setIsUpdate(!isUpdate);
			setShowModal(false);
		}
	};

	const handleChangeSex = (event) => {
		setSex(event.target.value);
	};

	const handleChangeCivilStatus = (event) => {
		setCivilStatus(event.target.value);
	};

	const handleChangeDepartment = async (event) => {
		setMunicipalitiesList([]);
		setCommunitiesList([]);
		setselectedDepartment(event.target.value);

		if (event.target.value !== '') {
			setIsLoading(true);
			const result = await getMunicipalitiesByDeptopAsync(token, event.target.value);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setMunicipalitiesList(result.data.result);
			setIsLoading(false);
		} else {
			setMunicipalitiesList([]);
		}
	};

	const handleChangeMunicipality = async (event) => {
		setCommunitiesList([]);
		setselectedMunicipality(event.target.value);

		if (event.target.value !== '') {
			setIsLoading(true);
			const result = await getCommunitiesByMunAsync(token, event.target.value);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setCommunitiesList(result.data.result);
			setIsLoading(false);
		} else {
			setCommunitiesList([]);
		}
	};

	const handleChangeComunity = (event) => {
		setselectedCommunity(event.target.value);
	};

	const clearData = () => {
		setFullName('');
		setPhone('');
		setIdentification('');
		setSex('');
		setCivilStatus('');
		setselectedDepartment('');
		setselectedMunicipality('');
		setselectedCommunity('');
		setAddress('');
		setActividadEconomica('');
		setCompany('');
		setCompanyAddress('');
	};

	const getCivilStatus = (data) => {
		if (data === 'SOLTERO' || data === 'SOLTERA') {
			return 0;
		} else if (data === 'CASADO' || data === 'CASADA') {
			return 1;
		} else {
			return 2;
		}
	};

	return (
		<div>
			<div>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							onChange={(e) => setFullName(e.target.value.toUpperCase())}
							style={{ marginTop: 20 }}
							value={fullName}
							label={'Nombre Completo'}
						/>
					</Grid>

					<Grid item xs={6}>
						<TextField
							fullWidth
							required
							onChange={(e) => setPhone(e.target.value.toUpperCase())}
							style={{ marginTop: 10 }}
							value={phone}
							label={'Telefono'}
						/>
						<TextField
							required
							fullWidth
							onChange={(e) => setIdentification(e.target.value.toUpperCase())}
							style={{ marginTop: 10 }}
							value={identification}
							label={'Cedula: 000-000000-0000X'}
						/>
						<FormControl fullWidth style={{ textAlign: 'left', marginTop: 10 }}>
							<InputLabel id="demo-simple-select-standard-label">Sexo</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={handleChangeSex}
								value={sex}
								label="Sexo"
							>
								<MenuItem value={0}>MASCULINO</MenuItem>
								<MenuItem value={1}>FEMENINO</MenuItem>
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ textAlign: 'left', marginTop: 10 }}>
							<InputLabel id="demo-simple-select-standard-label">
								Estado Civil
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={handleChangeCivilStatus}
								value={civilStatus}
								label="Estado Civil"
							>
								<MenuItem value={0}>{sex === 0 ? 'SOLTERO' : 'SOLTERA'}</MenuItem>
								<MenuItem value={1}>{sex === 0 ? 'CASADO' : 'CASADA'}</MenuItem>
								<MenuItem value={2}>UNION LIBRE</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={6}>
						<FormControl fullWidth style={{ marginTop: 10 }} required>
							<InputLabel id="demo-simple-select-standard-label">
								Departamento
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={selectedDepartment}
								onChange={handleChangeDepartment}
								label="Departamento"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Departamento</em>
								</MenuItem>
								{departmentList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ marginTop: 10 }} required>
							<InputLabel id="demo-simple-select-standard-label">
								Municipio
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={selectedMunicipality}
								onChange={handleChangeMunicipality}
								label="Municipio"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione un Municipio</em>
								</MenuItem>
								{MunicipalitiesList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>

						<FormControl fullWidth style={{ marginTop: 10 }} required>
							<InputLabel id="demo-simple-select-standard-label">
								Comunidad
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={selectedCommunity}
								onChange={handleChangeComunity}
								label="Comunidad"
								style={{ textAlign: 'left' }}
							>
								<MenuItem value="">
									<em>Seleccione una Comunidad</em>
								</MenuItem>
								{communitiesList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<TextField
							required
							fullWidth
							multiline
							onChange={(e) => setAddress(e.target.value.toUpperCase())}
							style={{ marginTop: 10 }}
							value={address}
							label={'Direccion'}
						/>
					</Grid>
				</Grid>

				<hr />

				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="space-between"
					spacing={2}
				>
					<TextField
						// style={{ marginTop: 10 }}
						required
						fullWidth
						onChange={(e) => setProfession(e.target.value.toUpperCase())}
						value={profession}
						label={'Profesion'}
					/>

					<TextField
						// style={{ marginTop: 10 }}
						required
						fullWidth
						onChange={(e) => setActividadEconomica(e.target.value.toUpperCase())}
						value={actividadEconomica}
						label={'Actividad Economica'}
					/>

					<TextField
						// style={{ marginTop: 10 }}
						required
						fullWidth
						onChange={(e) => setCompany(e.target.value.toUpperCase())}
						value={company}
						label={'Centro de trabajo'}
					/>
				</Stack>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="space-between"
					spacing={2}
				>
					<TextField
						required
						fullWidth
						multiline
						onChange={(e) => setCompanyAddress(e.target.value.toUpperCase())}
						style={{ marginTop: 10 }}
						value={companyAddress}
						label={'Direccion del trabajo'}
					/>
				</Stack>

				<Button
					variant="outlined"
					startIcon={<FontAwesomeIcon icon={faSave} style={{ marginRight: 10 }} />}
					fullWidth
					onClick={() => saveChanges()}
					style={{
						marginTop: 30,
						color: '#ffc400',
						borderColor: '#ffc400'
					}}
					size="large"
				>
					Actualizar fiador
				</Button>
			</div>
		</div>
	);
};

export default EditarFiador;
