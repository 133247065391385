import React, { useState, useContext } from 'react';
import { Paper, Container, Button, Stack, TextField } from '@mui/material';
import { DataContext } from '../../../../context/DataContext';
import { getToken } from '../../../../api/Account';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx/xlsx.mjs';

import { simpleMessage } from '../../../../helpers/Helpers';
import '../../../../helpers/estilo.css';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment/moment';
import { getConamiReportAsync } from '../../../../api/Report';

const SelectorSinRiesgo = () => {
	var date = new Date();
	const [fechaDesde, setFechaDesde] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
	const [fechaHasta, setFechaHasta] = useState(date);

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	const token = getToken();

	const verReport = async () => {
		if (!moment(fechaDesde).isValid()) {
			simpleMessage('Seleccione una fecha inicial válida', 'error');
			return;
		}
		if (!moment(fechaHasta).isValid()) {
			simpleMessage('Seleccione una fecha final válida', 'error');
			return;
		}

		await downLoadData();
	};

	const downLoadData = async () => {
		Swal.fire({
			title: '¿Estas Seguro?',
			text: 'Estas por descargar toda la base de credito...!',
			icon: 'question',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Descargar!',
			cancelButtonText: 'Cancelar',
			
			customClass: {
				container: 'my-swal',
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				(async () => {
					setIsLoading(true);
					const data = {
						desde: moment(fechaDesde).format('YYYY-MM-DD'),
						hasta: moment(fechaHasta).format('YYYY-MM-DD')
					};
					const result = await getConamiReportAsync(token, data);
					if (!result.statusResponse) {
						setIsLoading(false);
						if (result.error === 'Unauthorized') {
							setIsUnautorized(true);
							return;
						}
						simpleMessage(result.error, 'error');
						return;
					}
					exportExcel(result.data.result);
					setIsLoading(false);
					// Swal.fire('Descargado!', 'Datos Descargados...', 'success');
				})();
			}
		});
	};

	const exportExcel = (data) => {
		let exp = [];
		let exp2 = [];

		data.pages1.map((item) => {
			// console.log(item)
			let obj = {
				'Tipo de Entidad': item.tipoEntidad,
				'Número Correlativo': item.numeroCorrelativo,
				'Fecha de Reporte': moment(item.fechaReporte).format('L'),
				Departamento: item.departmentConamiCode,
				'Número de Identificación': item.identification,
				'Nombres y Apellidos': item.clientName,
				'Tipo de Crédito': item.tipoCredito,
				'Fecha de Desembolso': moment(item.fechaDesembolso).format('L'),
				'Tipo de Obligación': item.tipoObligacion,
				'Monto Autorizado (C+I)': item.montoAutorizado,
				'Plazo (Dias)': item.plazoDias,
				'Frecuencia de Pago': item.frecuenciaPago,
				'Saldo  de Deuda (a la fecha de reporte ).': item.saldoDeuda,
				'Estado del Crédito': item.estadoCredito,
				'Monto Vencido': item.montoVencido,
				'Antigüedad de Mora': item.antiguedadMora,
				'Tipo de Garantia': item.tipoGarantia,
				'Forma de Recuperación': item.formaRecuperacion,
				'Numero de credito': item.numeroCredito,
				'Monto Cuota': item.montoCuota
			};

			exp.push(obj);
		});
		data.pages2.map((item) => {
			let obj = {
				'Número Correlativo': item.numeroCorrelativo,
				'Fecha de Reporte': moment(item.fechaReporte).format('L'),
				Identificación: item.identification,
				'Tipo De Persona': item.tipoPersona,
				Nacionalidad: item.nacionalidad,
				'Fecha De Nacimiento': item.fechaNacimiento,
				Sexo: item.sexo,
				'Estado Civil': item.estadoCivil,
				'Dirección Domicilio': item.direccionDomicilio,
				'Departamento Domicilio': item.departamentoDomicilio,
				'Municipio Domicilio': item.municipioDomicilio,
				'Dirección Trabajo': item.direccionTrabajo,
				'Departamento Trabajo': item.departamentoTrabajo,
				'Municipio Trabajo': item.municipioTrabajo,
				'Telefono Domiciliar': item.telefonoDomiciliar,
				'Telefono Trabajo': item.telefonoTrabajo,
				Celular: item.celular,
				'Correo Electronico': item.email,
				Ocupacion: item.ocupacion,
				'Actividad Economica': item.actividadEconomica,
				Sector: item.sector
			};

			exp2.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp),
			bs2 = XLSX.utils.json_to_sheet(exp2);

		XLSX.utils.book_append_sheet(bk, bs, 'Hoja1');
		XLSX.utils.book_append_sheet(bk, bs2, 'Hoja2');
		XLSX.writeFile(bk, 'AYZ.xlsx');
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Paper
					elevation={10}
					style={{
						padding: 20
					}}
				>
					<Stack spacing={2}>
						{/* <Stack spacing={2} direction={'row'}> */}
						<DesktopDatePicker
							closeOnSelect
							label="Desde"
							inputFormat="DD/MM/YYYY"
							value={fechaDesde}
							onChange={(newValue) => setFechaDesde(newValue)}
							renderInput={(params) => <TextField {...params} />}
						/>

						<DesktopDatePicker
							closeOnSelect
							label="Hasta"
							inputFormat="DD/MM/YYYY"
							value={fechaHasta}
							onChange={(newValue) => setFechaHasta(newValue)}
							renderInput={(params) => <TextField {...params} />}
						/>
						{/* </Stack> */}
						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => verReport()}
							style={{
								color: '#4caf50',
								borderColor: '#4caf50'
							}}
							size="large"
						>
							Descargar Reporte
						</Button>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default SelectorSinRiesgo;
