import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { isEmpty } from "lodash";
import moment from "moment";

import { getToken } from "../../api/Account";
import { simpleMessage } from "../../helpers/Helpers";
import { DataContext } from "../../context/DataContext";
import NoData from "../../components/NoData";
import { getGastosAsync } from "../../api/Contabilidad";
import { PaginationComponent } from "../../components/PaginationComponent";

const Gastos = () => {
  const [gastosList, setGastosList] = useState([]);
  const [tipoGasto, setTipoGasto] = useState(1);

  var date = new Date();
  const [fechaDesde, setFechaDesde] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [fechaHasta, setFechaHasta] = useState(date);

  const [searchTerm, setSearchTerm] = useState("");
  const [sumGastos, setSumGastos] = useState(0);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const withSearch = gastosList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.referencia.toString().includes(searchTerm)) {
      return val;
    }
  });

  const token = getToken();

  useEffect(() => {
    (async () => {
      var start = moment(fechaDesde);
      var end = moment(fechaHasta);
      if (moment.duration(start.diff(end)).asDays() > 0) {
        simpleMessage("Rango de fechas incorrecto", "error");
        setFechaDesde(new Date(date.getFullYear(), date.getMonth(), 1));
        return;
      }

      setIsLoading(true);
      const data = {
        idTipoGasto: tipoGasto,
        desde: moment(fechaDesde).format("YYYY-MM-DD").toString(),
        hasta: moment(fechaHasta).format("YYYY-MM-DD").toString(),
      };
      const result = await getGastosAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setGastosList(result.data.result);
      let gastos = 0;
      result.data.result.map((item) => (gastos += item.entradas));
      setSumGastos(gastos);

      setIsLoading(false);
    })();
  }, [fechaDesde, fechaHasta, tipoGasto]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = withSearch.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography
              textAlign={"left"}
              color={"#00a152"}
              variant="h6"
              fontWeight={"bold"}
            >
              Historial de Gastos
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <DesktopDatePicker
                closeOnSelect
                label="Desde"
                inputFormat="DD/MM/YYYY"
                value={fechaDesde}
                onChange={(newValue) => setFechaDesde(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />

              <DesktopDatePicker
                closeOnSelect
                label="Hasta"
                inputFormat="DD/MM/YYYY"
                value={fechaHasta}
                onChange={(newValue) => setFechaHasta(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />

              <FormControl style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo de gastos
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => setTipoGasto(e.target.value)}
                  value={tipoGasto}
                  label="Tipo de gastos"
                >
                  <MenuItem value={1}>Gastos Administrativos</MenuItem>
                  <MenuItem value={2}>Gastos Operativos</MenuItem>
                  <MenuItem value={0}>Otros Gastos</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Descripcion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Monto
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fondos
                      </TableCell>

                      <TableCell align="left" className="text-primary fw-bold">
                        Realizado Por
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {moment(row.fecha).format("L")}
                          </TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(row.entradas)}
                          </TableCell>
                          <TableCell align="center">{row.payWith}</TableCell>
                          <TableCell align="left">
                            {`${row.realizadoPor.split(" ")[0]}  ${
                              row.realizadoPor.split(" ")[1]
                            }`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={gastosList}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />

              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                spacing={1}
              >
                <Typography
                  textAlign={"left"}
                  color={"#ffc400"}
                  variant="h6"
                  fontWeight={"bold"}
                >
                  Total de Gastos:
                </Typography>
                <Typography
                  variant="h6"
                  textAlign={"left"}
                  color={"#ffc400"}
                  fontWeight={"bold"}
                >
                  {new Intl.NumberFormat("es-NI", {
                    style: "currency",
                    currency: "NIO",
                  }).format(sumGastos)}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default Gastos;
