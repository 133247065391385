import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import {
  deleteToken,
  deleteUserData,
  getRol,
  getToken,
  getUser,
} from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getCreditsByGestorReportAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import {
  getGestoresAsync,
  getGestoresBySucursalAccessAsync,
  getSucursalByUserAsync,
} from "../../../api/Users";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";

const CreditsByGestor = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const compRef = useRef();

  const { selectedSucursal, selectedGestor, selectedSortBy } = useParams();

  const { setIsLoading, setIsLogged, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const [gestorName, setGestorName] = useState("");
  const [gestorList, setGestorList] = useState([]);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        selectedSortBy,
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
      };

      setIsLoading(true);
      const result = await getCreditsByGestorReportAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);
  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    let gestores = await getGestores(selectedSucursal);

    if (selectedGestor === "t") {
      setGestorName("Todos");
    } else {
      let name = gestores.filter(
        (item) => item.id.toString() === selectedGestor
      );

      setGestorName(name[0].fullName);
    }
    return result.data.result;
  };

  const getGestores = async (sucursal) => {
    setIsLoading(true);
    const result =
      sucursal === "t"
        ? await getGestoresBySucursalAccessAsync(token)
        : await getGestoresAsync(token, sucursal);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setGestorList(result.data.result);
    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 8mm; }
`;

  let totalP = 0;
  let totalR = 0;
  let totalSaldoC = 0;
  let totalSaldoInt = 0;
  let saldoT = 0;

  const exportExcel = () => {
    let exp = [];

    data.map((item) => {
      const dataGestor = gestorList.filter(
        (gestor) => gestor.id === item.gestor
      );
      let gestor = dataGestor[0];
      let obj = {
        cliente: item.fullName,
        principal: item.montoEntregado,
        montoPagar: item.montoPagar,
        periodicidad: item.periodicidad,
        plazo: item.plazo,
        fechaEntrega: moment(item.fechaEntrega).format("L"),
        fechaVencimiento: moment(item.fechaVencimiento).format("L"),
        saldoCapital: item.saldoPrincipal,
        saldoInteres: item.saldoInteresC,
        saldoTotal: item.saldoTotal,
        gestor: gestor.fullName,
        sucursal: item.sucursal,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "creditos");
    XLSX.writeFile(bk, "Creditos.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Creditos
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton
                    variant="outlined"
                    // style={{ position: "fixed", right: 20, top: 75 }}
                  >
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Parametros:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Sucursal: ${
                  selectedSucursal === "t" ? "Todas" : sucursalName
                }`}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Gestor Seleccionado: ${
                  selectedGestor === "t" ? "Todos" : gestorName
                }`}
              </Typography>

              <Typography fontSize={11} textAlign="left">
                {`Ordenados por: ${
                  parseInt(selectedSortBy) === 0 ? "Nombre" : "Monto"
                }`}
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Usuario:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {user}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {rol}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
              </Typography>
            </Stack>
          </Stack>
        </Container>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <Stack>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter(
                  (c) => c.sucursal === suc.description
                );

                return (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>
                    {gestorList.map((gestor) => {
                      const cGestor = sSucursal.filter(
                        (c) => c.gestor === gestor.id
                      );
                      let totalPrinc = 0;
                      let totalRecuperar = 0;
                      let totalSaldoCap = 0;
                      let totalSaldoI = 0;
                      let saldoTotal = 0;
                      cGestor.map((c) => {
                        totalPrinc += c.montoEntregado;
                        totalRecuperar += c.montoPagar;
                        totalSaldoCap += c.saldoPrincipal;
                        totalSaldoI += c.saldoInteresC;
                        saldoTotal += c.saldoTotal;
                      });
                      totalP += totalPrinc;
                      totalR += totalRecuperar;
                      totalSaldoC += totalSaldoCap;
                      totalSaldoInt += totalSaldoI;
                      saldoT += saldoTotal;
                      return isEmpty(cGestor) ? (
                        <></>
                      ) : (
                        <Stack padding={1} key={gestor.id}>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              sx={{
                                color: "#2196f3",
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              Gestor:
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              {`${gestor.fullName}`}
                            </Typography>
                          </Stack>
                          <Divider />
                          <TableContainer>
                            <Table aria-label="simple table" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Cliente
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Principal
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Monto Pagar
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Periodicidad
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Plazo
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    F.Entrega
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    F.Vencimiento
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    S.Capital
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    S.Interes
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    S.Total
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {cGestor.map((row) => {
                                  return (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.fullName}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.montoEntregado)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.montoPagar)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.periodicidad}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {row.plazo}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(row.fechaEntrega).format("L")}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(row.fechaEntrega).format("L")}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.saldoPrincipal)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.saldoInteresC)}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(row.saldoTotal)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <hr />

                          <Stack
                            direction="row"
                            flex="row"
                            justifyContent="space-around"
                          >
                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Creditos
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {cGestor.length}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Principal
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalPrinc)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total a Recuperar
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalRecuperar)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total S. Capital
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalSaldoCap)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total S. Interes
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(totalSaldoI)}
                              </Typography>
                            </Stack>

                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Saldo Total
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(saldoTotal)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <hr />
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          )}

          {gestorList.length === 1 ? (
            <></>
          ) : (
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                }}
              >
                Consolidado
              </Typography>
              <hr />

              <Stack direction="row" flex="row" justifyContent="space-around">
                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Creditos
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {data.length}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Principal
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalP)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total a Recuperar
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalR)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total S. Capital
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalSaldoC)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total S. Interes
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalSaldoInt)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Saldo Total
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(saldoT)}
                  </Typography>
                </Stack>
              </Stack>
              <hr />
            </Stack>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Creditos"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container
            fixed
            maxWidth="xl"
            sx={{ textAlign: "center", marginTop: 1 }}
          >
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Ordenados por: ${
                    selectedSortBy === 0 ? "Nombre" : "Promedio"
                  }`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth="xl" sx={{ textAlign: "center" }}>
            <hr />
            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {sucursalList.map((suc) => {
                  const sSucursal = data.filter(
                    (c) => c.sucursal === suc.description
                  );

                  return (
                    <Stack key={suc.description}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          Sucursal:
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          {`${suc.description}`}
                        </Typography>
                      </Stack>
                      {gestorList.map((gestor) => {
                        const cGestor = sSucursal.filter(
                          (c) => c.gestor === gestor.id
                        );
                        let totalPrinc = 0;
                        let totalRecuperar = 0;
                        let totalSaldoCap = 0;
                        let totalSaldoI = 0;
                        let saldoTotal = 0;
                        cGestor.map((c) => {
                          totalPrinc += c.montoEntregado;
                          totalRecuperar += c.montoPagar;
                          totalSaldoCap += c.saldoPrincipal;
                          totalSaldoI += c.saldoInteresC;
                          saldoTotal += c.saldoTotal;
                        });
                        totalP += totalPrinc;
                        totalR += totalRecuperar;
                        totalSaldoC += totalSaldoCap;
                        totalSaldoInt += totalSaldoI;
                        saldoT += saldoTotal;
                        return isEmpty(cGestor) ? (
                          <></>
                        ) : (
                          <Stack
                            justifyContent={"space-between"}
                            key={gestor.id}
                          >
                            <Stack direction="row" spacing={1}>
                              <Typography
                                sx={{
                                  color: "#2196f3",
                                  textAlign: "left",
                                }}
                                fontSize={11}
                              >
                                Gestor:
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={11}
                              >
                                {gestor.fullName}
                              </Typography>
                            </Stack>

                            <Divider />

                            <TableContainer
                              aria-label="simple table"
                              size="small"
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="left"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Cliente
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Principal
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Monto Pagar
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Periodicidad
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      Plazo
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      F.Entrega
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      F.Vencimiento
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      S.Capital
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      S.Interes
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 10 }}
                                    >
                                      S.Total
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cGestor.map((row) => {
                                    return (
                                      <TableRow key={row.id}>
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {row.fullName}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.montoEntregado)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.montoPagar)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {row.periodicidad}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {row.plazo}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {moment(row.fechaEntrega).format("L")}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {moment(row.fechaEntrega).format("L")}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.saldoPrincipal)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.saldoInteresC)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          scope="row"
                                          className="text-dark"
                                          style={{ fontSize: 8 }}
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.saldoTotal)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <hr />

                            <Stack
                              direction="row"
                              flex="row"
                              justifyContent="space-around"
                            >
                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Creditos
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {cGestor.length}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Principal
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalPrinc)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total a Recuperar
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalRecuperar)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total S. Capital
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalSaldoCap)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total S. Interes
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalSaldoI)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Saldo Total
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(saldoTotal)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <hr />
                          </Stack>
                        );
                      })}
                    </Stack>
                  );
                })}
              </>
            )}
          </Container>

          <Container maxWidth="xl" sx={{ textAlign: "center" }}>
            {gestorList.length === 1 ? (
              <></>
            ) : (
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "center",
                  }}
                >
                  Consolidado
                </Typography>
                <hr />

                <Stack direction="row" flex="row" justifyContent="space-around">
                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total Creditos
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {data.length}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total Principal
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalP)}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total a Recuperar
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalR)}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total S. Capital
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalSaldoC)}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Total S. Interes
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(totalSaldoInt)}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Saldo Total
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(saldoT)}
                    </Typography>
                  </Stack>
                </Stack>
                <hr />
              </Stack>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default CreditsByGestor;
