import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";

import SmallModal from "../../../components/modals/SmallModal";

import { getConceptoGastosAsync } from "../../../api/Contabilidad";
import AddConceptoGasto from "./AddConceptoGasto";

const ConceptoGastoList = () => {
  const [conceptoList, setConceptoList] = useState([]);
  const [tipoGasto, setTipogasto] = useState(1);
  const [localReload, setLocalReload] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const withSearch = conceptoList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.description.toString().includes(searchTerm)) {
      return val;
    }
  });

  const { access, setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();
  useEffect(() => {
    (async () => {
      await getData(tipoGasto);
    })();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = conceptoList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChangeSelect = async (id) => {
    setTipogasto(id);
    await getData(id);
  };

  const getData = async (id) => {
    setIsLoading(true);
    const result = await getConceptoGastosAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setConceptoList(result.data.result);
    setIsLoading(false);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Conceptos de Gasto
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl style={{ textAlign: "left" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Tipo Gasto
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => handleChangeSelect(e.target.value)}
                  value={tipoGasto}
                  label="Tipo Gasto"
                >
                  <MenuItem value={1}>Gastos Administrativos</MenuItem>
                  <MenuItem value={2}>Gastos Operativos</MenuItem>
                  <MenuItem value={3}>Otros Gastos</MenuItem>
                </Select>
              </FormControl>

              {isAccess(access, "CONFIG CREATE") ? (
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                  variant="outlined"
                  style={{
                    color: "#00a152",
                    borderColor: "#00a152",
                  }}
                >
                  Agregar Concepto
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Concepto
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const { description, id } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>

                          <TableCell align="left" component="th">
                            {description}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={"Agregar Concepto"}
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddConceptoGasto
          setShowModal={setShowModal}
          localReload={localReload}
          setLocalReload={setLocalReload}
        />
      </SmallModal>
    </div>
  );
};

export default ConceptoGastoList;
